import React, { Component } from 'react'

class GsIcon extends Component {
    render() {
        let iconName = this.props.name || ''
        let iconSize = this.props.size || 24

        return (
            <span style={{ verticalAlign: 'middle', padding: '5px' }}>
                <img src={require('@/assets/icons/' + iconName + '.png')} lazy-load="{{true}}" style=
                    {{
                        width: `${iconSize}px`,
                        height: `${iconSize}px`,
                    }
                    }
                    alt="icon" />
            </span>
        )
    }
}

export default GsIcon
