import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CircleLoading from '@/components/loading/circleLoading';
import { getQueryVariable } from '../../utils/tool'

const loadTime = 10

@withRouter
class Loading extends Component {
    state = {
        batchBizNo:'',
        applyBingResult:{}
    }

    // 组件挂载后输出
    componentDidMount() {
        let routerProps = this.props.location.search || '';
        const batchBizNo = getQueryVariable('batchBizNo', routerProps);
        this.setState({
            batchBizNo:batchBizNo,
        })
        setTimeout(()=>{
            this.props.history.replace(`/signResult?batchBizNo=${batchBizNo}`)
        },10000)
    }

    // 组件更新
    componentWillUnmount() {}


    render() {
        return (
            <div className="full-width text-center mt40">
                <CircleLoading loadTime={loadTime}/>
            </div>
        )
    }
}
export default Loading
