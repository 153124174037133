import {
  $post,
  $get
} from '@/services'

// 登录-验证码
export const getCodeSrc = () => {
  return $post('/auth/getVerifyCode', {}, true)
}
export const loginRequest = (loginInfo) => {
  return $post('/auth/bus/login', loginInfo, true)
}

export const getAccountType = (params) => {
  return $post('/partner/account/type/query', params)
}

export const getMsgCode = (params) => {
  return $post('/auth/app/sendSmsCode', params, true)
}

// 获取渠道帐号选择列表
export const queryAccountList = () => {
  return $post('/agent/account/switch-list/query', {})
}

// 获取合作商帐号选择列表
export const queryPartnerList = () => {
  return $post('/partner/account/switch-list/query', {})
}

// 渠道帐号确认登陆 
export const agentAccountChooseConfirm = (params, accountType = 'agent') => {
  return $post(`/${accountType}/account/confirm/finish`, params)
}

// 首页-查询渠道相关信息
export const queryAccountInfo = (accountType = 'agent') => {
  return $post(`/${accountType}/account/${accountType}-relative/query`, {})
}

// 我的-查询认证状态 && 人脸识别信息
export const queryAccountAuth = (accountType = 'agent') => {
  return $post(`/${accountType}/account/authentication/judge`, {})
}

// 我的-查询认证信息
export const queryAccountAuthDetail = (accountType = 'agent') => {
  return $post(`/${accountType}/account/authentication/query`, {})
}

// 查询当前帐号的默认签约列表类型
export const queryDefaultSignType = (accountType = 'agent') => {
  return $post(`/${accountType}/sign-center/v3/default-type`, {})
}

// 分页查询签约文件列表
export const querySignList = (params, accountType = 'agent') => {
  return $post(`/${accountType}/sign-center/v3/page-query`, params)
}

// 批量查询签约文件详情
export const querySignDetail = (params, accountType = 'agent') => {
  return $post(`/${accountType}/sign-center/v3/batch/get`, params)
}

// 签约发送短信
export const sendSignSms = (params, accountType = 'agent') => {
  return $post(`/${accountType}/sign-center/v3/batch/sign-send-sms`, params)
}

// 同意/拒绝签约
export const submitSign = (params, accountType = 'agent') => {
  return $post(`/${accountType}/sign-center/v3/batch/sign-submit`, params)
}

// 签约成功查询下一份待签约文件
export const queryNextSign = (params) => {
  return $post('/agent/sign-center/document/next-to-sign', params)
}

// 自然人-人脸识别-第三方地址
export const queryFaceAuthUrl = (params) => {
  return $post('/agent/account/face/identify/url', params)
}

// 根据自然人账号查询自然人基本信息
export const queryBaseInfo = () => {
  return $post('/agent/account/flexible-employment-person/base-info')
}

// 银行卡列表
export const queryBankList = () => {
  return $get('/agent/account/authentication/bank/list')
}

// 根据key换取url地址
export const queryBatchUrl = (params) =>{
  return $post('/common/data/agent/query-batch-url', params)
}  

// 绑卡申请
export const applyBingCard = (params) =>{
  return $post('/agent/account/authentication/bank/apply/bing-card', params)
}

// 银行卡绑卡
export const bingSubmit = (params) =>{
  return $post('/agent/account/authentication/bank/sms-submit', params)
}

// 登录签约人
export const bingSignatory = (params) =>{
  return $get('/agent/account/authentication/bank/find/signatory', params)
}

// 获取活动信息
export const activityQuery = (params) => {
  return $post('/agent/account/info/activity/query', params)
}

// 获取开户绑卡结果
export const accountResult = (params, accountType = 'agent') => {
  return $post(`/${accountType}/account/authentication/bank/open/account/result`, params)
}

// 批量获取签约结果
export const batchSignResult = (params) => {
  return $post('/agent/sign-center/v3/sign-result', params)
}

// 查询渠道运维BD信息
export const queryBdInfo = () => {
  return $post('/common/data/agent/query-merchant-bd')
}