import React, { Component } from 'react'
import notLess from './notFound.module.less'
import { Button } from 'antd-mobile'
export default class NotFound extends Component {
  goHome = () => {
    console.log('this.props', this.props);
    this.props.history.replace('/')
  }

  goBack = () => {
    console.log('this.props', this.props);
    this.props.history.goBack()
  }

  render() {
    return (
      <div className={notLess.wrapper}>
        <div className={notLess.notfound}>
          <p className="pall20 font-midl font-bold-mid primary">抱歉！页面不存在，建议您</p>
          <div className="text-center mt20">
            <Button onClick={() => this.goHome()} type="ghost" inline className="mr10">返回首页</Button>
            <Button onClick={() => this.goBack()} type="primary" inline className="ml10">后退一步</Button>
          </div>
        </div>
      </div>
    )
  }
}
