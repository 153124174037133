import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { PhotoProvider, PhotoConsumer } from 'react-photo-view'
import 'react-photo-view/dist/index.css'
import { Button, Modal, Toast, Checkbox } from 'antd-mobile'
import { querySignDetail, submitSign } from '@/requests'
import { getQueryVariable } from '../../../utils/tool'
import signInfoModule from './signInfo.module.less'

const alert = Modal.alert
@withRouter
class SignCenter extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    iniState() {
        console.log('iniState@@')
        this.setState({
            isGetSign: -1,
            documentIds: '',
            documentInfoList:[], // 文档信息集合
            buttonList:[], // 按钮集合
            pinganImgOss:'', // 平安用户文件osskey
            pinganImgUrl:'', // 平安用户图片文件Url 有就必须勾选，没有就不用
            digitalImgOss:'',  // 平安数字osskey
            digitalImgUrl: '',  // 平安数字图片文件Url 有就必须勾选，没有就不用
            bankType: 1, // bankType  1:平安 2:中信 
            loginAccountType: localStorage.getItem('loginAccountType')
        })
    }

    // 获取路由参数
    componentDidMount() {
        let routerProps = this.props.location.search || '';
        let documentIds = getQueryVariable('documentIds', routerProps); // 编号
            documentIds = documentIds.split(",").map(Number)
        this.iniState()
        this.setState({ documentIds: documentIds }, () => {
            this.getSignDetail()
        });
    }

    //组件更新时被调用 
    componentWillReceiveProps() {
        let routerProps = this.props.location.search || '';
        let documentIds = getQueryVariable('documentIds', routerProps); // 编号
            documentIds = documentIds.split(",").map(Number)
            this.iniState()
            this.setState({ documentIds: documentIds }, () => {
                this.getSignDetail()
            });
    }

    // 获取签约详情
    getSignDetail = () => {
        let curDocumentId = this.state.documentIds
        if (!curDocumentId || !curDocumentId.length) {
            return
        }
        const loginAccountType = this.state.loginAccountType
        querySignDetail({ documentIds: curDocumentId }, loginAccountType === 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success && res.model) {
                    this.setState({
                        isGetSign: true,
                        documentInfoList: res.model.documentInfoList,
                        buttonList: res.model.buttonList,
                        pinganImgOss: res.model.pinganImgOss,
                        pinganImgUrl: res.model.pinganImgUrl,
                        digitalImgOss:res.model.digitalImgOss,
                        digitalImgUrl: res.model.digitalImgUrl,
                        bankType: res.model.bankType,
                    })
                } else {
                    this.setState({
                        isGetSign: false
                    })
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                this.setState({
                    isGetSign: false
                })
                console.error(error);
            });
    }

    // 同意/拒绝签约 1:同意 2：拒绝 3：暂不签约
    signSubmit = (signType) => {
        console.log('')
        const loginAccountType = this.state.loginAccountType
        let documentList = []
        this.state.documentInfoList.forEach(element => {
            documentList.push({
                documentId:element.documentId,
                pdfOss:element.pdfOss
            })
        });
        // 3.暂不签约 - 签约首页
        if (signType === 3) {
            this.handleGoAdmin();
        }
        // 2.拒绝 - 结果页
        if (signType === 2) {
            alert('提示', '确定拒绝签约？', [
                {
                    text: '我再想想',
                    onPress: () => { }
                },
                {
                    text: '确认',
                    onPress: () => {
                        // 接口
                        let params = {
                            documentIds: this.state.documentIds,
                            submitFlag: 2
                        }
                        submitSign(params, loginAccountType == 3 ? 'partner' : 'agent')
                            .then(res => {
                                if (res.success) {
                                    this.props.history.push(`/signResult?batchBizNo=${res.model}`)
                                } else {
                                    Toast.fail(res.errorMessage);
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                },
            ])
        }
        // 1.同意 - 验证页 
        if (signType === 1) {
            if(this.state.bankType === 1){
                // 平安
                if(this.state.pinganImgOss && this.state.digitalImgOss && !this.state.agreePingAnProtocol){
                    // 平安绑卡类型
                    this.setState({
                        isShowTips: true
                    })
                    return
                }
                this.props.history.push(`/signConfirm?documentIds=${this.state.documentIds}`)
            }else{
                this.props.history.push(`/signConfirm?documentIds=${this.state.documentIds}`)
            }
        }
    }

    // 签约首页
    handleGoAdmin() {
        this.props.history.replace(`/admin`)
    }

    // 去平安数字用户服务协议
    handleGoPicture = (key) => {
        this.props.history.push(`/picture?ossKey=${key}`)
    }


    render() {
        let {isGetSign , agreePingAnProtocol, isShowTips, pinganImgOss, digitalImgOss, documentInfoList, buttonList, bankType } = this.state

        return (
            <div>
                {isGetSign ?
                    <div>
                        <div className="bottomFixedContent">
                            {documentInfoList.length ? <PhotoProvider photoClosable="true">
                                {documentInfoList.map((item, index) => (
                                    <PhotoConsumer key={index} src={item.imgUrl}>
                                        <img className="full-width" src={item.imgUrl} alt="签约文件" />
                                    </PhotoConsumer>
                                ))}
                            </PhotoProvider>:''
                            }
                        </div>
                        <div style={{display: buttonList.length ? 'block':'none'}} className={`pb20 ${signInfoModule.bottomFixedWrapper}`}>
                            <div style={{display: pinganImgOss && digitalImgOss && bankType === 1 ? 'block':'none'}}>
                                <div className={['plr20 mt20'].join(' ')} style={{ position: 'relative' }}>
                                    {isShowTips && <span className='protoTiscom'>请先阅读并晓平安合作协议后，再进行签约</span>}
                                    <Checkbox
                                        checked={agreePingAnProtocol}
                                        onChange={(event) => { 
                                            this.setState({ agreePingAnProtocol: event.target.checked })
                                            if (isShowTips) {
                                                this.setState({ isShowTips: false })
                                            }
                                        }}
                                        >
                                    </Checkbox>
                                    <span className='ml10'>我已阅读并知晓</span>
                                    <span className="primary text-vertical" onClick={() => this.handleGoPicture(this.state.digitalImgOss)} >《平安数字用户服务协议》</span>
                                    <span>和</span>
                                    <span className="primary text-vertical" onClick={() => this.handleGoPicture(this.state.pinganImgOss)} >《平安银行见证宝与商户协议》</span>
                                </div>
                            </div>
                            <div className = "text-center mt20 box-flex plr10" >
                                { buttonList.length ? buttonList.map((val,index)=>{
                                    return <Button key={index} onClick={() => this.signSubmit(val.value)} className={['flex-1','mlr5', index === 0 ? signInfoModule.wbtn : signInfoModule.btn].join(' ')}>{val.text}</Button>
                                }) : ''}
                            </div>
                        </div>
                    </div>
                    : <div>
                        <div className='centerWrapper'>
                            <div className='centerWrapperContent'>
                                <p className="pall20 font-midl font-bold-mid primary"></p>
                                <div className="text-center mt20">
                                    <Button onClick={() => this.handleGoAdmin()} type="primary" inline className="ml10">返回签约中心</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default SignCenter
