import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Flex, List, Toast, Modal} from 'antd-mobile'
import personalCenterModule from './personalCenter.module.less'
import BdPhone from '@/components/bdPhone/bdPhone'
import { queryAccountInfo, queryAccountAuth, queryFaceAuthUrl, bingSignatory, queryBaseInfo } from '@/requests'
import { connect } from 'react-redux'
import { loginFailed } from '@/actions/user'

const mapStateToProps = state => ({})

const Item = List.Item
// 头部-个人信息
@withRouter
class UserInfo extends Component {

    render() {
        let { userInfo } = this.props // accountType: 1-渠道商帐号 2-自然人帐号

        return (
            <div>
                { userInfo &&
                    <div className="banner-Wrapper pbb80 banner-Wrapper-after">
                        <Flex justify="between">
                            <div className="font-big font-bold-mid">
                                {userInfo.accountType === 1 ? (userInfo.agentName || '')  : userInfo.accountType === 3 ? (userInfo.partnerName || '') :  '欢迎您，' + (userInfo.accountName || '')}
                            </div>
                        </Flex>
                        <div style={{marginTop:'8px'}} className="box-flex justify-between align-items-center font-sml">
                            {userInfo.accountType === 2 && <span className="flex-1">授权方：{userInfo.agentName || ''}</span>}
                            {userInfo.channelManagerName && 
                                <BdPhone>
                                    <span className="light-green">渠道经理：{userInfo.channelManagerName}</ span>
                                </BdPhone>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}
@connect(mapStateToProps, { loginFailed })
@withRouter
class PersonalCenter extends Component {
    state = {
        //认证结果 authenticationStatus 0.未通过  1.已通过
        authInfo: {},
        quitModel: false,
        userInfo: {}, // accountType: 1-渠道商帐号 2-自然人帐号
        faceUrlBtnLoading: false, // 点击人脸识别url的按钮loading
        queryBaseInfo:{},
        bingSignatoryInfo:{},
        loginAccountType: ''
    }

    componentDidMount() {
        this.setState({
            loginAccountType: localStorage.getItem('loginAccountType')
        },()=>{
            this.queryAccountAgentInfo()
            if(this.state.loginAccountType != 3){
                this.queryBaseInfo() 
            }
            this.getAccountAuthInfo()
        })
        this.bingSignatory()
    }

    // 我的-查询认证状态
    getAccountAuthInfo = () => {
        const loginAccountType =  this.state.loginAccountType
        queryAccountAuth(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    this.setState({
                        authInfo: res.model
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
               console.error(error);
            });
    }

    // 首页-查询渠道相关信息
    queryAccountAgentInfo = () => {
        const loginAccountType =  this.state.loginAccountType
        queryAccountInfo(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    this.setState({
                        userInfo: res.model
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }


    // 跳转认证详情
    handleAuthMenu = (authResult) => {
        authResult ? this.props.history.push('/authInfo') : Toast.info('请联系渠道经理进行实名认证', 1.5);
    }

    // 跳转第三方人脸认证
    handleFaceMenu = (ifIdentify) => {
        // 认证识别通过点击无反应 1.未识别 2.已识别
        if (!ifIdentify || this.state.faceUrlBtnLoading) {
            return
        }

        this.setState({
            faceUrlBtnLoading: true
        })
        // 获取当前登录人的H5认证地址
        queryFaceAuthUrl()
            .then(res => {
                this.setState({
                    faceUrlBtnLoading: false
                })
                if (res.success) {
                    let authUrl = res.model && res.model.faceIdentifyUrl
                    if (authUrl) {
                        window.location.href = authUrl
                    }
                } else {
                   Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    // Modal弹框
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    onWrapTouchStart = (e) => {
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    // 退出登录
    handleSubmitQuit = key => () => {
        // 退出接口
        this.setState({
            [key]: false,
        });
        localStorage.removeItem('loginToken')
        localStorage.removeItem('loginAccount')
        localStorage.removeItem('loginPhone')
        localStorage.removeItem('loginFace')
        this.props.loginFailed()
        this.props.history.replace('/login')
    }

    // 
    async queryBaseInfo () {
       const  {success, model} = await queryBaseInfo();
       if(success){
        this.setState({
            queryBaseInfo: model,
        })
       }else{
        this.setState({
            queryBaseInfo: {},
        })
       }
    }

    async bingSignatory (){
        const  {success, model} = await bingSignatory();
        if(success){
            this.setState({
                bingSignatoryInfo: model || {},
            })
        }else{
            this.setState({
                bingSignatoryInfo: {},
            })
        }
    }

    // 管理提现跳转
    manageAccont = () => {
        window.location.href = `${this.state.queryBaseInfo.cancelRegisterUrl}`
    }

    // 银行卡列表
    goBankList =() =>{
        this.props.history.push(`/bankCard`)
    }

    render() {
        const { authInfo, quitModel, userInfo, bingSignatoryInfo, loginAccountType } = this.state
        // 人脸识别 faceIdentifyStatus 1.未识别 2.已识别

        return (
            <div className={personalCenterModule.personalCenterWrapper}>
                <UserInfo userInfo={userInfo} />
                <List className="my-list">
                    {loginAccountType != 3 && <Item 
                        multipleLine 
                        arrow="horizontal" 
                        thumb={require("@/assets/images/center_auth.png")} 
                        extra={<span className={[authInfo.authenticationStatus === 1 && 'warning', 'font-sml'].join(' ')} > 
                        {authInfo.authenticationStatus ? '已认证' : '未认证'} </span>} 
                        onClick={() => this.handleAuthMenu(authInfo.authenticationStatus)} >
                        认证中心
                    </Item>}
                    {/* {userInfo.accountType === 2 && <Item multipleLine arrow={authInfo.faceIdentifyStatus === 2 ? 'empty' : 'horizontal'} thumb={require("@/assets/images/center_face.png")} extra={<span className={[authInfo.faceIdentifyStatus === 2 && 'warning', 'font-sml box-flex align-items-center justify-end'].join(' ')}><span className="mr10 box-flex align-items-center">{faceUrlBtnLoading && <Icon type="loading" size="xxs"></Icon>}</span><span>{authInfo.faceIdentifyStatusDesc}</span> </span>} onClick={() => this.handleFaceMenu(authInfo.ifIdentify)} >人脸识别</Item>
                    } */}
                    {loginAccountType != 3 && <Item 
                        multipleLine
                        arrow="horizontal" 
                        style={this.state.queryBaseInfo.merchantRegister === 2 ?{display:'flex'}:{display:'none'}} 
                        thumb={require("@/assets/images/center_withdrawal.png")} 
                        onClick={this.manageAccont}>
                        管理提现账户
                    </Item>}
                    {loginAccountType != 3 && <Item 
                        multipleLine 
                        arrow="horizontal" 
                        style={ Object.keys(bingSignatoryInfo).length > 0  && bingSignatoryInfo.bankType === 1 ?{display:'flex'}:{display:'none'}} 
                        thumb={require("@/assets/images/center_account.png")} 
                        onClick={this.goBankList}>
                        银行卡绑定
                    </Item>}
                    <Item multipleLine arrow="empty" thumb={require("@/assets/images/center_set.png")} onClick={this.showModal('quitModel')} >
                        退出登录
                    </Item>
                </List>
                <Modal
                    popup
                    visible={quitModel}
                    onClose={this.onClose('quitModel')}
                    animationType="slide-up"
                    className="personalQuitResetWrapper"
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                >
                    <div className="black font-mid">
                        <div className="quitModelItem danger" onClick={this.handleSubmitQuit('quitModel')}>退出</div>
                        <div className="quitModelItem" onClick={this.onClose('quitModel')}>取消</div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default PersonalCenter
