import React, { Component } from 'react'
const pageRander = WrappedComponent => {
    return class extends Component {
        componentWillUnmount = () => {
            console.log("componentWillUnMount------return")
            if (this.timer) {
                clearTimeout(this.timer)
                clearInterval(this.timer)
            }
            window.onScroll = null
            this.setState = (state, callback) => {
                return
            }
        }
        render() {
            return <WrappedComponent />
        }
    }
}

export default pageRander