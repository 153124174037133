import React, { Component } from 'react'
import { Button, InputItem, Toast, ActivityIndicator } from 'antd-mobile'
import signCenterModule from '../SignCenter/signCenter.module.less'
import bindingCard from './bindingCard.module.less';
import { withRouter } from 'react-router-dom'
import { applyBingCard, bingSubmit } from '@/requests'
import { getQueryVariable } from '../../utils/tool'
import Card from '@/components/card/card';
import Code from '@/components/code/code'

const COUNTNUM = 120
@withRouter
class BindingCard extends Component {
    state = {
        topTiptext:'为了保障您的账户安全，需要对开户手机号进行短信验证，带来不便，请您谅解！',
        tipErrorText:'验证码输入错误，请输入正确的验证码', // 提示文案
        agentNo: '', // 渠道商编号
        cardId: '', //银行卡id
        showCount: false, // 是否展示计数
        smsCode:'', // 验证码code
        countNumber: COUNTNUM, // 计时基数
        isLoading:false, //是否在加载中
        transferAmount:'', // 平安转账金额
        authenticationNumber:'', // 鉴权序号
        applyBingCardInfo:{  // 绑卡申请model
            agentNo:'', // 渠道商编号
            bankNo:'', // 银行卡号
            bankName: '', // 开户行
            phone:'', // 手机号
            status: '', // 银行卡认证结果 1 成功 2/3 失败 4 成功（重试） 5 超过当日次数 6 未开户导致绑卡失败
            reason:'', // 失败原因
            processType: '', // 绑定类型 1. 验证码  2. 小额打款
            flag:1 // 是否重试 0 重试 1.不重试
        },
        resultInfo:{}, // 绑卡结果详情
        applyBingCardSuccess:true,
        from:'',
        applyBingCardResMessage:'',
        animating:false,
        authenticationInputFocus:false,
        smsCodeInputFocus:false,
        stimer: null
    }

    // 获取路由参数
    componentDidMount() {
        let routerProps = this.props.location.search || '';
        const agentNo = getQueryVariable('agentNo', routerProps) // 设置渠道商编码
        const cardId = getQueryVariable('cardId', routerProps) // 设置银行卡账号 
        const From = getQueryVariable('comeFrom', routerProps) // 设置银行卡账号 
        this.setState({
            agentNo:agentNo,
            cardId:cardId,
            from:From,
            animating:!this.state.animating
        })
        let param = {};
        if(cardId){
            // cardId 存在
            param.cardId = cardId 
        }
        applyBingCard(param).then(res=>{
            this.setState({ animating: !this.state.animating });
            if (res.success) {
                this.setState({
                    applyBingCardInfo: res.model
                })
                if (this.state.applyBingCardInfo.status === 1 || this.state.applyBingCardInfo.status === 4) {
                    // 绑卡申请成功
                    this.countDownHandle() // 自动跑秒
                }
            } else {
                this.setState({
                    applyBingCardSuccess: res.success,
                    applyBingCardResMessage: res.errorMessage,
                })
            }
        })
        
    }
    // 输入验证码
    smsCodeChange = (smsCode) => {
        this.setState({
            smsCode
        },()=>{
            if (this.stimer !== null) clearTimeout(this.stimer);
            this.stimer = setTimeout(() => {
                if (smsCode.length === 6) {
                    this.signSubmit()
                }
            },500)
        })
    }
    // 输入鉴权序号
    authenticationChange = (authenticationNumber) =>{
        this.setState({
            authenticationNumber
        })
    }
    // 输入金额
    transferAmountChange = (transferAmount) =>{
        this.setState({
            transferAmount
        })
    }

    // 倒计时
    countDownHandle = () => {
        if (this.state.showCount) return
        this.setState(preState => ({
            countNumber: preState.countNumber - 1,
            showCount: true
        }))
        this.timer = setInterval(() => {
            if (this.state.countNumber <= 1) {
                clearInterval(this.timer)
                this.setState((preState) => ({
                    showCount: false,
                    countNumber: COUNTNUM
                }))
            }
            this.setState(preState => ({
                countNumber: preState.countNumber - 1
            }))
        }, 1000)
    }
    // 提交
    signSubmit = ()=> {
        const { applyBingCardInfo, transferAmount, smsCode, authenticationNumber } = this.state;
        const param = {
            bindType:applyBingCardInfo.processType,
            code: smsCode,
            orderNo:authenticationNumber,
            amount:transferAmount,
            signChannel:2
        }
        if(this.state.cardId){
            // cardId 存在
            param.cardId = this.state.cardId
        }
        bingSubmit(param).then(res=>{
            if(res.success){
                // 提交成功
                this.goSignResult();
            }else{
                this.setState({
                    resultInfo:res
                })
                Toast.fail(res.errorMessage);
            }
        })
    }
    // 获取验证码
    gobind = () =>{
        this.countDownHandle()
        let param = {};
        if(this.state.cardId){
            // cardId 存在
            param.cardId = this.state.cardId
        }
        applyBingCard(param).then(res=>{
            if (res.success) {
                if(res.model.status === 1){
                    Toast.info('验证码发送成功');
                }else if(res.model.status === 5 || res.model.status === 4){
                    Toast.info(res.model.reason);
                }
                this.setState({
                    applyBingCardInfo: res.model
                })
            } else {
                Toast.fail(res.errorMessage);
            }
        })
    }
    // 获取鉴权序号
    getAuthCode = () => {
        this.countDownHandle()
        let param = {};
        if(this.state.cardId){
            // cardId 存在
            param.cardId = this.state.cardId
        }
        applyBingCard(param).then(res=>{
            if (res.success) {
                if(res.model.status === 1){
                    Toast.info('鉴权序号发送成功');
                }else if(res.model.status === 5 || res.model.status === 4 ){
                    Toast.info(res.model.reason);
                }
                this.setState({
                    applyBingCardInfo: res.model
                })
            } else {
                Toast.fail(res.errorMessage);
            }
        })
    }
    // 跳转结果
    goSignResult = () => {
        if(this.state.from === 'bankList'){
            // 从银行卡列表页面过来
            this.props.history.replace(`/bankCard?initialPage=1`) 
        }else{
            this.props.history.replace(`/signResult?source=bindingCarding`)
        }
    }

    goBindCardFile= () =>{
        this.props.history.replace(`/signResult?source=bindingCarding&type=fail`)
    }


    // 获取序号
    getCode = () => {
        let param = {};
        if(this.state.cardId){
            // cardId 存在
            param.cardId = this.state.cardId
        }
        applyBingCard(param).then(res=>{
            if (res.success) {
                if(res.model.status === 1){
                    Toast.info('验证码发送成功');
                }else if(res.model.status === 5 || res.model.status === 4){
                    Toast.info(res.model.reason);
                }
            } else {
                Toast.fail(res.errorMessage);
            }
        })
    }


    render() {
        const { 
            showCount,
            isLoading, 
            countNumber, 
            transferAmount, 
            authenticationNumber, 
            applyBingCardInfo, 
            topTiptext, 
            resultInfo, 
            applyBingCardSuccess, 
            applyBingCardResMessage, 
            animating
        } = this.state
        return (
            <div className={['font-sml bindCard' , signCenterModule.signConfirmWrapper, bindingCard.bindCardWraper].join(" ")}>
                {/* 加载中的提示 */}
                <ActivityIndicator toast text="加载中..." animating={animating}/>
                {/* 提示 */}
                <div className={`${signCenterModule.topTip} mb20`}>{topTiptext}</div>
                {/* 开户或者绑卡失败 */}
                {(applyBingCardInfo.status === 2 || applyBingCardInfo.status === 3 || !applyBingCardSuccess || applyBingCardInfo.status === 6) && 
                    <div>
                        <div className={bindingCard.errorTipView}>
                            <div className={bindingCard.title}>{applyBingCardInfo.status === 6 ? '开户失败' : '银行卡认证失败'}</div>
                            <div className={bindingCard.titleTip}>{!applyBingCardSuccess ? applyBingCardResMessage : applyBingCardInfo.reason};请联系渠道经理处理。</div>
                        </div>
                        <div className={bindingCard.getKnowBtn} onClick={() => this.goBindCardFile()}>我已知晓</div>
                    </div>
                }

                {/* 银行卡卡片 */}
                <Card bankName={applyBingCardInfo.bankName} bankNo={applyBingCardInfo.bankNo}></Card>

                {/* 联系电话 */}
                <div style={{display: applyBingCardInfo.phone ? 'block':'none'}} className={bindingCard.phoneWrap}>
                    <div className={bindingCard.title}>联系电话</div>
                    <div className={bindingCard.phoneValue}>{applyBingCardInfo.phone}</div>
                </div>

                {/* 绑卡 */}
                <div style={{display: applyBingCardInfo.status === 1 || applyBingCardInfo.status === 4 || applyBingCardInfo.status === 5 ? 'block':'none'}}>
                    {/* 短信绑卡 applyBingCardInfo.processType === 1 */}
                    {applyBingCardInfo.processType === 1 && 
                        <div className={bindingCard.shortMessWrap}>
                            <div className={bindingCard.title}>请输入验证码</div>
                            <Code codeMaxLength={6} inputValueChange={(value) => this.smsCodeChange(value)}></Code>
                            {applyBingCardInfo.status === 5 && <div className='mtb10 primary'>已超过当日获取验证码次数，请明日再试。</div>}
                            {(!resultInfo.success && resultInfo.errorMessage) && <div className='mtb10 danger'>{resultInfo.errorMessage}</div>}
                            <div className="mt10 pall10 text-center color-8C8C8C">
                                {showCount ? <span>{countNumber < 10 ? `0${countNumber}` : countNumber}s后重发</span> : <span onClick={()=> this.gobind()} className='primary'>获取验证码</span>}
                            </div>
                            
                        </div>
                    }
                    {/* 小额绑卡 applyBingCardInfo.processType === 2 */}
                    {applyBingCardInfo.processType === 2 && 
                        <div className={bindingCard.smallPayWrap}>
                            <div className={bindingCard.title}>鉴权序号</div>
                            <div className={bindingCard.titleTip}>鉴权序号可在<span className={bindingCard.span}>手机短信</span>中查看</div>
                            <div style={{width:'70%'}}>
                                <Code codeMaxLength={4} inputValueChange={(value) => this.authenticationChange(value)}></Code>
                            </div>
                            <div className="mt20 text-center color-8C8C8C">
                                {showCount ? 
                                <span>{countNumber < 10 ? `0${countNumber}` : countNumber}s后重发</span> : 
                                <span onClick={ ()=> this.getAuthCode() } className='primary'>获取鉴权序号</span>}
                            </div>
                            <div className={bindingCard.line}></div>
                            <div className={bindingCard.title}>转账金额(元)</div>
                            <div className={bindingCard.titleTip}>转账金额请在<span className={bindingCard.span}>收款银行卡</span>中查看</div>
                            <InputItem 
                                value={transferAmount}
                                className='mtb10' 
                                type="money"
                                onChange={this.transferAmountChange}
                                clear 
                                moneyKeyboardAlign="left"
                                placeholder="请输入平安转账金额">
                            </InputItem>
                            <div 
                                style={{display: ( Number(transferAmount) <0  || Number(transferAmount) > 0.5 || (!Number(transferAmount) && Number(transferAmount) !== 0)) ? 'block':'none'}} 
                                className='mtb10 danger'>
                                请输入0～0.5的转账金额
                            </div>
                            { applyBingCardInfo.status === 5 && <div className='mtb10 primary'>已超过当日获取验证码次数，请明日再试。</div> }
                            { !resultInfo.success && <div className='mtb10 danger'>{resultInfo.errorMessage}</div> }
                            <div className={bindingCard.bottomBtnWrap}>
                                <div className='text-center mtb10 '>
                                    没收到短信？<span className='primary' onClick={ ()=> this.getCode() }>再次发送短信</span> 
                                </div>
                                <div className={bindingCard.btn}>
                                    <Button 
                                        loading={isLoading} 
                                        disabled={authenticationNumber.length !==4 || transferAmount === '' || Number(transferAmount) <0  || Number(transferAmount) > 0.5 || (!Number(transferAmount) && Number(transferAmount) !== 0)} 
                                        onClick={() => this.signSubmit()} type="primary">
                                        完成
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        ) 
    }
}
export default BindingCard