import { WhiteSpace, Toast } from 'antd-mobile'
import React, { Component } from 'react'
import { queryAccountAuthDetail } from '@/requests'

class AuthInfo extends Component {
    state = {
        // 认证结果: certificationResult 0. 未通过 1. 已通过
        // 帐号类型: accountType 1 - 渠道商帐号 2 - 自然人帐号
        authInfo: {},
        //1.渠道商认证中心信息展示字段：法人姓名、社会统一信用代码、法人身份证号、企业名称
        //2.自然人认证中心信息展示字段：姓名、身份证号、手机号
        showAuthInfo: [[
            {
                label: "法人姓名",
                prop: "legalName"
            }, {
                label: "社会统一信用代码",
                prop: "creditNo"
            }, {
                label: "法人身份证号",
                prop: "legalIdentificationNo"
            }, {
                label: "企业名称",
                prop: "companyName"
            }], [
            {
                label: "姓名",
                prop: "legalName"
            }, {
                label: "身份证号",
                prop: "legalIdentificationNo"
            }, {
                label: "手机号",
                prop: "phone"
            }]],
            loginAccountType: ''
    }

    componentDidMount() {
        this.setState({
            loginAccountType: localStorage.getItem('loginAccountType')
        },()=>{
            this.getAccountAuthContent()
        })
    }

    // 我的-查询认证信息 
    getAccountAuthContent = () => {
        const loginAccountType =  this.state.loginAccountType
        queryAccountAuthDetail(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    this.setState({
                        authInfo: res.model
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        const { authInfo, showAuthInfo } = this.state
        return (
            <div className="pall20 font-sml">
                {authInfo.certificationResult && <div className="pall10 border-radius10 warning bg-gray">实名信息{authInfo.certificationResult ? '已认证' : '未认证'}</div>}
                <WhiteSpace size="lg" />
                <div className="pall10 border-radius10 bg-gray">
                    {
                        authInfo.accountType && showAuthInfo[(authInfo.accountType - 1)*1].map((item, index) => {
                            return (<div className="pall5 mb10" key={index}>
                                <div className="gray-font">{item.label}</div>
                                <div className="mt5">{authInfo[item.prop]}</div>
                            </div>)
                        })
                    }
                </div>
            </div>
        )
    }
}
export default AuthInfo