import React, { Component } from 'react'
import CardModule from './card.module.less';
class Card extends Component {
    render() {
        const { bankName, bankNo } = this.props
        return (bankName || bankNo) ? (<div className={CardModule.CardWrap}>
                {bankName && <div className={CardModule.bankName}>{bankName}</div>}
                <div className={CardModule.bankNameTip}>储蓄卡</div>
                {bankNo && <div className={CardModule.bankNo}>{bankNo}</div>}
            </div>) : ''
    }
}
export default Card