import axios from 'axios'
import linkUtil from '@/utils/config'

const removeUserInfo = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('loginToken')
  localStorage.removeItem('loginAccount')
  localStorage.removeItem('loginPhone')
  localStorage.removeItem('loginFace')
  window.location.replace('/login')
}

const service = (isAuth) => {
  const instance = axios.create({
    // isAuth 代表是否直接调用权限中心接口
    baseURL: isAuth ? linkUtil.auth_url : linkUtil.base_url,
    headers: {
      'X-CSRF-TOKEN': localStorage.getItem('loginToken') || '',
      sysType: 7,
      token: localStorage.getItem('token') || '',
      'SECURITY_AUTH_KEY': 'MHS_AGENT_FLAG'
    }
  })
  // http response 拦截器
  instance.interceptors.response.use(
    response => {
      //拦截响应，做统一处理 
      if (!response.data.success && ['MHS400001', 'MHS400002', 'MHS400003'].includes(response.data.errorCode)) {
        removeUserInfo()
      }
      return response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
      if (!error.response || [500, 404].includes(error.response.status)) {
        alert('网络延迟，请稍后尝试！');
      } else if ([401, 403].includes(error.response.status)) {
        removeUserInfo()
      }
      return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })
  return instance
}

export const $get = (url, isAuth = false) => (
  service(isAuth).get(url)
  .then(resp => {
    return Promise.resolve(resp.data)
  }).catch(error => {
    return Promise.reject(error)
  })
)

export const $post = (url, data = {}, isAuth = false) => (
  service(isAuth).post(url, data)
  .then(resp => {
    return Promise.resolve(resp.data)
  }).catch(error => {
    return Promise.reject(error)
  })
)