import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import { queryFaceAuthUrl } from '@/requests'


@withRouter
class FaceAuth extends Component {
    state = {
        // 湾道对接API：0.人脸识别成功，文件未下载 1.人脸识别成功，文件已下载 2.人脸识别失败 
        // 可能错误码 12003 实名认证失败 12031 人脸识别通过，勿重复操作
        code: 0
    }

    getUrlParam(queryName) {
        let param = new URLSearchParams('?' + window.location.hash.split('?')[1]);
        return param.get(queryName) || ''
    }

    // 获取路由参数
    componentDidMount() {
        const didMountCode = this.getUrlParam('code') * 1 || 0
        this.setState({ code: didMountCode }, () => {
            this.goNextPage();
        });

    }

    //组件更新时被调用 
    componentWillReceiveProps(newProps) {
        const newMountCode = this.getUrlParam('code') * 1 || 0
        if (newMountCode !== this.state.code) {
            this.setState({ code: newMountCode }, () => {
                this.goNextPage();
            });
        }
    }

    goNextPage() {
        if (this.state.code === 2) {// 失败，自动发起下次人脸识别--第三方链接
            // 获取当前登录人的H5认证地址
            queryFaceAuthUrl()
                .then(res => {
                    if (res.success) {
                        let authUrl = res.model && res.model.faceIdentifyUrl
                        if (authUrl) {
                            window.location.href = authUrl
                        }
                    } else {
                        Toast.fail(res.errorMessage);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } else {// 其他 - 系统首页
            this.props.history.replace(`/admin`)
        }
    }

    render() {
        return (
            <div>
                <div className="loader-box">
                    <div className="loader-content">
                        <img className="loader-contentImg" src={require("@/assets/images/loading.gif")} alt="" />
                        <div className="loader-tips">
                            <p>加载中…</p>
                            <p>请勿关闭页面</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FaceAuth