import actionTypes from './actionTypes'
import {
  loginRequest,
  queryAccountList,
  getAccountType,
  queryPartnerList
} from '@/requests'
import {
  Toast
} from 'antd-mobile'
import {
  agentAccountChooseConfirm
} from '@/requests'


const startLogin = () => {
  return {
    type: actionTypes.START_LOGIN
  }
}

export const loginSuccess = () => {
  return {
    type: actionTypes.LOGIN_SUCCESS
  }
}

export const loginFailed = () => {
  return {
    type: actionTypes.LOGIN_FAILED
  }
}

export const login = userInfo => {
  return dispatch => {
    dispatch(startLogin())
    loginRequest(userInfo)
      .then(res => {
        if (res.success) {
          localStorage.setItem('loginToken', res.model.token) // 账号token
          localStorage.setItem('loginPhone', res.model.phoneNumber)
          getAccountType().then(res=>{
            const { success = false, model = [], errorMessage = '' } = res
            if(!success){
              Toast.info(errorMessage);
              return
            }
            if(model.includes(3)){
              // 代表是合作商
              localStorage.setItem('loginAccountType', 3) // 1-渠道商帐号 2-自然人帐号 3-合作商账号
            }else{
              // 渠道商
              localStorage.setItem('loginAccountType', 1) // 1-渠道商帐号 2-自然人帐号 3-合作商账号
            }
            if(model.includes(3)){
              // 合作商账号
              queryPartnerList().then(res=>{
                const { success = false, model = {
                  defaultAccountNo: '',
                  switchAccountList: []
                }, errorMessage = '' } = res
                if(!success){
                  Toast.info(errorMessage);
                  return
                }
                let selectCurAccount = ""
                if (model.defaultAccountNo || model.switchAccountList.length === 1) {
                  selectCurAccount = model.defaultAccountNo ? model.defaultAccountNo : model.switchAccountList[0].accountNo
                  agentAccountChooseConfirm({
                    accountNo: selectCurAccount,
                    isSelected: true
                  }, 'partner')
                    .then(loginRes => {
                      if (loginRes.success) {
                        localStorage.setItem('loginAccount', selectCurAccount)
                        localStorage.setItem('loginFace', 1) // 首次登陆 弹框人脸识别一次
                        dispatch(loginSuccess())
                        userInfo.history.push(`/admin`)
                      } else {
                        Toast.fail(loginRes.errorMessage);
                      }
                    })
                    .catch(error => {
                      console.error(error);
                    });
                }
              })
            }else{
              // 其他账号
              // 判断是否对应多个渠道商
              queryAccountList()
              .then(switchRes => {
                if (switchRes.success) {
                  let {
                    switchAccountList,
                    defaultAccountNo
                  } = switchRes.model
                  let selectCurAccount = ""
                  // 有默认账户或只有一个账号
                  // 真正登录过程
                  if (defaultAccountNo || switchAccountList.length === 1) {
                    selectCurAccount = defaultAccountNo ? defaultAccountNo : switchAccountList[0].accountNo
                    agentAccountChooseConfirm({
                      accountNo: selectCurAccount,
                      isSelected: true
                    })
                      .then(loginRes => {
                        if (loginRes.success) {
                          localStorage.setItem('loginAccount', selectCurAccount)
                          localStorage.setItem('loginFace', 1) // 首次登陆 弹框人脸识别一次
                          dispatch(loginSuccess())
                          userInfo.history.push(`/admin`)
                        } else {
                          Toast.fail(loginRes.errorMessage);
                        }
                      })
                      .catch(error => {
                        console.error(error);
                      });
                  } else {
                    // 多个账号跳转选择账号页面
                    userInfo.history.push(`/selectAccount`)
                  }
                } else {
                  Toast.info(switchRes.errorMessage || '登录失败');
                }
              })
            }
          }) 
        } else {
          Toast.info(res.errorMessage || '验证登录失败');
          dispatch(loginFailed())
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(loginFailed())
      })
  }
}