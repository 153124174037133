import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Flex, Toast, Modal, Button } from 'antd-mobile'
import signCenterModule from './signCenter.module.less'
import GsIcon from '@/components/gsicon/gsicon'
import BdPhone from '@/components/bdPhone/bdPhone'
import SignListLoad from './signListLoad/index'
import { queryAccountInfo, queryDefaultSignType, queryAccountAuth, queryFaceAuthUrl, queryAccountList, queryBaseInfo } from '@/requests'
import pageRander from '@/utils/pageRander'

// 头部-个人信息
@withRouter
class UserInfo extends Component {

    state = {
        switchAccountList:[],
        loginAccountType: localStorage.getItem('loginAccountType')
    }

    // 获取渠道商可选列表
    getAccountList = () => {
        queryAccountList()
            .then(res => {
                if (res.success) {
                    this.setState(() => {
                        return {
                            switchAccountList: res.model.switchAccountList
                        }
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    // 跳转页面
    goPageRouter = (routerName) => {
        console.log('routerNamerouterName=>',routerName)
        this.props.history.push(`/${routerName}`)
    }

    componentDidMount() {
        this.getAccountList()
    }


    render() {
        let { userInfo } = this.props
        const { switchAccountList, loginAccountType } = this.state

        return (
            < div>
                { userInfo &&
                    <div className="banner-Wrapper banner-Wrapper-after">
                        <div className="font-big font-bold-mid" >
                            {userInfo.accountType === 1 ? (userInfo.agentName || '') : userInfo.accountType === 3 ? (userInfo.partnerName || '')  : '欢迎您，' + (userInfo.accountName || '')}
                        </div>
                        <div style={{marginTop:'5px'}} className="box-flex justify-between align-items-center font-sml">
                            {userInfo.accountType === 2 && <span className="flex-1">授权方：{userInfo.agentName || ''}</span>}
                            {userInfo.channelManagerName && 
                                <BdPhone className="flex-1">
                                    <span className="light-green">渠道经理：{userInfo.channelManagerName}</ span>
                                </BdPhone>
                            }
                            {
                                (userInfo.accountType === 1 && switchAccountList.length > 1 && loginAccountType !== 3 ) ?
                                    <span onClick={() => this.goPageRouter('selectAccount')} className={['font-bold-mid', signCenterModule.switchAccountBtn].join(' ')}><GsIcon name={'switch'} size={'15'} />切换渠道商</span>
                                    : ''
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// 签约Tab列表
class SignInfo extends Component {
    state = {
        queryType: 1,//1-待签约 2-已签约
        allDataNum: -1,
        waitSignNum: 0,
        signedShow: 0,
        faceInfo: {},
        // ifIdentify // 是否需要人脸识别
        // ifSkip // 人脸识别是否可以跳过

        isFaceLogin: 0, // 初次登陆弹框
        showFacePop: false, // 显示弹框
        faceUrlBtnLoading: false,// 点击人脸识别url的按钮loading
        allSelect:false,
        loginAccountType: ''
    }

    componentDidMount() {
        this.setState({
            loginAccountType: localStorage.getItem('loginAccountType')
        },()=>{
            this.getDefaultSignType(); 
        })
        // 自然人--获取人脸识别结果
        // if (this.props.accountType === 2) {
        //     this.getFaceSkip()
        // }
    }

    componentWillReceiveProps(nextProps) {
        // 自然人--获取人脸识别结果
        // if (nextProps.accountType === 2) {
        //     this.getFaceSkip()
        // }
    }

    // 查询当前帐号的默认签约列表类型
    getDefaultSignType = () => {
        const loginAccountType =  this.state.loginAccountType
        queryDefaultSignType(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    let signDocInfo = res.model;
                    this.setState({
                        queryType: signDocInfo.defaultSignType || 1,
                        allDataNum: signDocInfo.waitSignNum * 1 + signDocInfo.hasSignNum * 1,
                        waitSignNum: signDocInfo.waitSignNum * 1,
                        waitSignTip: signDocInfo.waitSignTip || '',
                        signedShow: signDocInfo.waitSignNum * 1 + signDocInfo.hasSignNum * 1,
                    })
                    if(loginAccountType != 3){
                        queryBaseInfo().then(res=>{
                            if (res.success) {
                                if(res.model.registerIsShow === 1) {
                                    this.setState({
                                        signedShow: signDocInfo.waitSignNum * 1 + signDocInfo.hasSignNum * 1 + 1
                                    })
                                }
                            }
                        })
                    }
                }
            })
    }

    // 获取当前账号人脸识别状态
    getFaceSkip = () => {
        const loginAccountType =  this.state.loginAccountType
        this.setState({
            isFaceLogin: localStorage.getItem('loginFace') * 1
        })
        queryAccountAuth(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    let data = res.model;
                    this.setState({
                        faceInfo: {
                            ifIdentify: data.ifIdentify, // 是否需要人脸识别
                            ifSkip: data.ifSkip, // 人脸识别是否可以跳过
                        },
                        showFacePop: data.ifIdentify && (!data.ifSkip || this.state.isFaceLogin === 1)
                    })
                }
            })
    }

    // 切换签约文件列表tab
    changeSignType = (tabIndex) => {
        this.setState({
            queryType: tabIndex
        })
    }

    // 人脸识别跳转第三方
    goFaceAuth = (isAuth) => {
        localStorage.setItem('loginFace', 0) // 弹框一次

        if (!isAuth) {
            this.setState({
                showFacePop: false
            })
            return
        }

        this.setState({
            faceUrlBtnLoading: true
        })
        // 获取当前登录人的H5认证地址
        queryFaceAuthUrl()
            .then(res => {
                this.setState({
                    showFacePop: false,
                    faceUrlBtnLoading: false
                })
                if (res.success) {
                    let authUrl = res.model && res.model.faceIdentifyUrl
                    if (authUrl) {
                        window.location.href = authUrl
                    }
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        let { queryType, allDataNum, waitSignTip, faceInfo, showFacePop, faceUrlBtnLoading, signedShow } = this.state
        let accountType = this.props.accountType // accountType: 1-渠道商帐号 2-自然人帐号
        let userName = this.props.userName

        const signTabs = [
            { tabIndex: 1, title: '待签约' },
            { tabIndex: 2, title: '已签约' }
        ];

        return (
            <div>
                <div className="commonTabWrapper mt5">
                    <Flex>
                        {signTabs.map((item, index) => {
                            return <Flex.Item key={index}>
                                <div className="text-center font-mid" onClick={() => this.changeSignType(item.tabIndex)}>
                                    <span className={['commonTabName', item.tabIndex === queryType ? 'border-primary primary font-bold-sml' : 'border-trans'].join(' ')}>{item.title}</span></div>
                            </Flex.Item>
                        })}
                    </Flex>
                    <div key="waitSign" style={{ display: queryType === 1 ? 'block' : 'none' }} >
                        {
                            waitSignTip && < div className = "plr20 mt20" >
                                <div className="pall10 border-radius10 warning bg-gray">
                                    {waitSignTip}
                                </div>
                            </div>
                        }
                        {accountType ? <SignListLoad changeTab={this.changeSignType.bind(this)} queryType={'1'} accountType={accountType} isHasAnyData={signedShow} /> : ''}
                    </div>
                    <div key="doneSign" style={{ display: queryType === 2 ? 'block' : 'none' }} >
                        {accountType ? <SignListLoad changeTab={this.changeSignType.bind(this)} queryType={'2'} accountType={accountType} isHasAnyData={allDataNum} /> : ''}
                    </div>
                </div>
                {accountType === 2 && <div>
                    {/* 展示条件  自然人
                     1.需要人脸识别 ifIdentify==true
                     2.登录过来 && 不可跳过 ifSkip==false
                    */}
                    <Modal popup visible={showFacePop} className={signCenterModule.faceModal}>
                        <div className={signCenterModule.faceModalImg}>
                            <img src={require("@/assets/images/pop_face.png")} alt="" />
                        </div>
                        <div className="black text-left mt10">
                            <p className="font-bold-sml">尊敬的{userName}:</p>
                            <p className="mt5">为了确保签约流程为本人操作，请您配合录入人脸信息。</p>
                        </div>
                        <div className={[signCenterModule.faceModalBtnList, 'mt10'].join(' ')}>
                            <Button loading={faceUrlBtnLoading} type="primary" onClick={() => this.goFaceAuth(true)}>前往识别</Button>
                            {/* 可配置：展示跳过*/}
                            {faceInfo.ifSkip && <div className="mt10 font-bold-sml" onClick={() => this.goFaceAuth(false)}>跳过</div>}
                        </div>
                    </Modal>
                </div>}
            </div>
        );
    }
}

@pageRander
@withRouter
class SignCenter extends Component {
    state = {
        userInfo: {}, // accountType:1-渠道商帐号 2-自然人帐号
        loginAccountType: ''
    }

    componentDidMount() {
        console.log('loginAccountType=>', localStorage.getItem('loginAccountType'))
        this.setState({
            loginAccountType: localStorage.getItem('loginAccountType')
        },()=>{
            this.queryAccountAgentInfo()
        })
    }

    // 首页-查询渠道相关信息
    queryAccountAgentInfo = () => {
        console.log('loginAccountType2=>', this.state.loginAccountType)
        const loginAccountType =  this.state.loginAccountType
        queryAccountInfo(loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                if (res.success) {
                    this.setState({
                        userInfo: res.model
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        let { userInfo } = this.state
        return (
            <div className={signCenterModule.signCenterWrapper}>
                <UserInfo userInfo={userInfo} />
                <SignInfo accountType={
                    userInfo.accountType
                }
                    userName={userInfo.accountType === 1 ? (userInfo.agentName || '') : (userInfo.accountName || '')}
                />
            </div>
        )
    }
}

export default SignCenter
