let domain_url = window.location.host
let base_url = 'https://wgw.enmonster.com/mhs'
let auth_url = 'https://uag.enmonster.com'

// 开发使用
let devUrlList = {
    base_url: 'https://wgw.qa.enmonster.com/mhs',
    auth_url: 'https://uag.qa.enmonster.com'
}

if (process.env.NODE_ENV === 'development') {
    base_url = devUrlList.base_url
    auth_url = devUrlList.auth_url
} else {
    if (domain_url.includes('pre.')) {
        base_url = 'https://wgw.pre.enmonster.com/mhs'
        auth_url = 'https://uag.pre.enmonster.com'
    } else if (domain_url.includes('gr.')) {
        base_url = 'https://wgw.gr.enmonster.com/mhs'
        auth_url = 'https://uag.gr.enmonster.com'
    } else if (domain_url.includes('yc.')) {
        base_url = 'https://wgw.yc.enmonster.com/mhs'
        auth_url = 'https://uag.yc.enmonster.com'
    } else if (domain_url.includes('qa.')) {
        base_url = 'https://wgw.qa.enmonster.com/mhs'
        auth_url = 'https://uag.qa.enmonster.com'
    } else {
        base_url = 'https://wgw.enmonster.com/mhs'
        auth_url = 'https://uag.enmonster.com'
    }
}

console.log("linkUtil---auth_url", auth_url)
console.log("linkUtil---base_url", base_url)

export default {
    "base_url": base_url,
    "auth_url": auth_url
}