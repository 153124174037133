import React, { Component, useState } from 'react'
import { Flex, WhiteSpace, WingBlank, Checkbox, Button, Toast } from 'antd-mobile';
import selectAccountModule from './selectAccount.module.less'
import { queryAccountList, agentAccountChooseConfirm } from '@/requests'
import { connect } from 'react-redux'
import { loginSuccess } from '@/actions/user'

const mapStateToProps = state => ({})

let AccountListUl = (props) => {
    const [selectCurAccount, setAccount] = useState(0)
    const callCurAccount = (accountItem) => {
        setAccount(accountItem)
        props.getChooseAccount(accountItem)
    }
    let accountList = props.accountList
    let listItems = accountList.map((accountItem) =>
        <li className={[selectAccountModule.accountContent, selectCurAccount === accountItem.accountNo ? selectAccountModule.accountActive : ''].join(' ')} key={accountItem.accountNo} onClick={() => callCurAccount(accountItem.accountNo)} >
            <Flex direction="column" align="start" justify="center">
                <span className={`single-ellipsis align-self ${selectAccountModule.accountName}`}>{accountItem.agentName}</span>
                <span className={ selectCurAccount === accountItem.accountNo ? selectAccountModule.label : selectAccountModule.displayNone}></span>
                {accountItem.businessType === 1 && <WhiteSpace size="xs" />}
                {accountItem.businessType === 1 && <span className='light-gray'>待签约{accountItem.businessNumber}份文件</span>}
            </Flex>
        </li>
    );
    return (
        <ul>{listItems}</ul>
    );
}

@connect(mapStateToProps, { loginSuccess })
class SelectAccount extends Component {
    state = {
        accountList: [],
        selectCurAccount: '',
        isSelected: false
    }

    componentDidMount() {
        this.getAccountList()
    }

    // 获取渠道商可选列表
    getAccountList = () => {
        queryAccountList()
            .then(res => {
                if (res.success) {
                    let model = res.model
                    this.setState(() => {
                        return {
                            accountList: model.switchAccountList
                        }
                    })
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    // 设置所选账号
    getChooseAccount = (cur) => {
        this.setState({
            selectCurAccount: cur
        })
    }

    // 选择账号确认登录
    switchAccountSubmit = () => {
        const { selectCurAccount, isSelected } = this.state

        agentAccountChooseConfirm({
            accountNo: selectCurAccount,
            isSelected
        })
            .then(res => {
                if (res.success) {
                    localStorage.setItem('loginAccount', selectCurAccount)
                    localStorage.setItem('loginFace', 1) // 首次登陆 弹框人脸识别一次
                    this.props.loginSuccess()
                    this.props.history.replace(`/admin`)
                } else {
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        const { accountList, selectCurAccount } = this.state
        return (
            <div className={selectAccountModule.selectAccountWrapper}>
                <WingBlank size="lg" >
                    <WhiteSpace size="lg" />
                    <AccountListUl getChooseAccount={this.getChooseAccount} accountList={accountList} />
                    <WhiteSpace size="lg" />
                </WingBlank>
                <div className="bottomFixedWrapper">
                    <div className="pall20">
                    {selectCurAccount &&
                            <Flex className="mb10 full-width" align="start">
                                <Checkbox
                                    checked={this.state.isSelected}
                                    onChange={(event) => {
                                        this.setState({ isSelected: event.target.checked })
                                    }}
                                >
                                    <span className="ml5 gray font-mini">
                                        <span className={selectAccountModule.chooseText}>下次默认登陆</span>
                                        <span className={["primary single-ellipsis", selectAccountModule.chooseText, selectAccountModule.chooseAccount].join(' ')}>{accountList.filter(item => item.accountNo === selectCurAccount)[0].agentName}</span>
                                        <span className={selectAccountModule.chooseText}>进入</span>
                                    </span>
                                </Checkbox>
                            </Flex>
                        }
                        <Button type="primary" disabled={!selectCurAccount} onClick={this.switchAccountSubmit}>确定</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectAccount
