import React, { Component } from 'react'
import { Modal } from 'antd-mobile'
function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

class ProtocolLoginPage extends Component {
    state = {
        showLoginProtocol: false
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    showModal = () => {
        // e.preventDefault(); // 修复 Android 上点击穿透
        console.log('showLoginProtocol')
        this.setState({
            showLoginProtocol: true
        });
    }

    onClose = () => () => {
        this.setState({
            showLoginProtocol: false
        });
    }

    onWrapTouchStart = (e) => {
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }


    render() {
        return (
            <div>
                <Modal
                    visible={this.state.showLoginProtocol}
                    transparent
                    maskClosable={false}
                    onClose={this.onClose('showLoginProtocol')}
                    title="用户协议"
                    footer={[{ text: '我知道了', onPress: () => { this.onClose('showLoginProtocol')() } }]}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                >
                    <div className="font-sml" style={{ height: 300, overflow: 'scroll', textAlign: 'left' }}>
                        <p>
                            “怪兽充电”软件服务（以下简称“服务”）系由挚享科技（上海）有限公司 （以下简称“挚享科技”）开发并与挚享科技关联方一同依本协议及您与挚享科技关联方的相关合同（具体关联方以您所签合同为准，以下统称为“挚享科技”）为您提供相应服务与业务支持的软件系统。挚享科技在此特别提醒您（用户）在注册成为用户之前，请认真阅读本用户协议（以下简称“协议”），确保您充分理解本协议中各条款，且您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。请您审慎阅读并选择接受或不接受本协议，如果您不同意本协议的任何条款，则不访问或使用“怪兽充电”所包含的任何信息，除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
                        </p>
                        <p>一、用户注册</p>
                        <p>1、“用户”是指注册、登录、使用本服务的人。用户在使用本服务前需注册本平台账号及完善信息，否则视为游客登入，将不得使用注册账户的相关功能。</p>
                        <p>2、在用户注册及使用本服务时，本服务需要搜集能识别用户信息以便可以在必要时联系用户，或为用户提供更好的使用体验。其搜集的信息包括但不限于用户的姓名、身份证、电话、公司信息等信息；本软件同意对这些信息的使用将受限于隐私信息保护的约束。</p>
                        <p>二、服务内容及服务须知</p>
                        <p>1、为使用本软件提供的服务，用户应：</p>
                        <p>（1）自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；</p>
                        <p>（2）自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；</p>
                        <p>（3）选择与所安装终端设备相匹配的软件版本。</p>
                        <p>2、基于本软件所提供的网络服务的重要性，用户确认并同意：</p>
                        <p>（1）提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新；</p>
                        <p>（2）如用户提供的注册资料不合法、不真实、不准确、不详尽的，用户将需自行承担因此引起的相应责任及后果，并且挚享科技有权自行决定中止、终止用户的密码、 帐户（或其任何部分）或提供部分或全部服务，有权暂时冻结或永久冻结（注销）用户的账户，并可在任何时间出于任何原因或没有原因地删除用户在本软件的任何内容。此外，用户同意挚享科技无须为上述行为向用户或任何第三方承担任何责任。</p>
                        <p>（3）若用户反对任何条款和条件或对挚享科技提供的服务有任何不满意，用户应立即终止帐户的使用，并停止使用挚享科技就本协议项下提供的全部服务。</p>
                        <p>三、用户使用规则</p>
                        <p>1、用户在注册成为本软件的用户后，应采取合理措施确保账户的安全并对其妥善保管，用户对基于其账号下发生的行为承担责任。因用户未遵守本条款规定所造成的损害，由用户自行承担。</p>
                        <p>2、用户在使用本系统提供的服务时，应遵守以下义务：</p>
                        <p>（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>
                        <p>（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>
                        <p>（3）不得利用本软件从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
                        <p>（4）不得干扰本软件的正常运转，不得侵入本软件及国家计算机信息系统；</p>
                        <p>（5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</p>
                        <p>（6） 不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
                        <p>（7）不得教唆他人从事本条所禁止的行为；</p>
                        <p>（8）不得利用在本软件注册的账户进行牟利性经营活动；</p>
                        <p>（9）不得发布任何侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容；</p>
                        <p>3、用户应遵守本条款的各项条款，正确、适当地使用本服务，如因用户违反本条款中的任何条款，挚享科技在通知用户后有权依据条款中断或终止对违约用户账号提供服务。</p>
                        <p>4、用户应保持个人信息的有效性、完整性，在用户信息发生变更时，应及时在本软件上自行更新。用户承担上传发布的内容责任，挚享科技不对任何有关信息内容的真实性、适用性及合法性承担责任。</p>
                        <p>四、隐私保护及授权</p>
                        <p>1、为方便用户使用本软件相关服务，本软件将记录并存储用户在使用时的必要信息，在法律规定范围内自行决定单个用户在本软件及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。除法律法规规定的情形外，未经用户的许可挚享科技不会向第三方公开、透露用户的个人信息。挚享科技将对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。</p>
                        <p>2、用户充分理解并同意：</p>
                        <p>（1）为配合行政监管机关、司法机关执行工作，在法律规定范围内挚享科技有权向上述行政、司法机关提供用户在使用本软件时所储存的相关信息，包括但不限于用户的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；</p>
                        <p>（2）本公司依法保障用户在安装或使用过程中的知情权和选择权，在用户使用本软件服务过程中，涉及用户设备自带功能的服务会提前征得用户同意，用户一经确认，本公司有权开启包括但不限于收集地理位置、读取通讯录、使用摄像头、启用录音等提供服务必要的辅助功能。</p>
                        <p>3、用户理解并同意，为改善用户服务的目的，挚享科技或其关联公司可能通过邮件、短信、电话、站内信等形式使用用户的个人信息，向用户提供可能感兴趣的信息，包括但不限于向用户发出产品和服务信息，或通过系统向用户展示个性化的第三方推广信息，或者与挚享科技授权经销商、代理商、第三方服务提供商及其他合作伙伴向用户发送有关其产品、服务、调查及统计的信息（后者需要用户的事先同意）目的使用。未经用户的允许，以上数据使用方均不会透露给其它任何第三方。我们将适当地使用用户的个人信息来实现我们的服务。</p>
                        <p>4、用户的个人信息将储存在一个受保护的安全环境中，公众无法访问这些信息。如果我们认为这些信息有助于我们更好地为用户提供服务，在尊重用户意愿的前提下，我们将会长久地保存下去。</p>
                        <p>五、知识产权声明及软件使用规范</p>
                        <p>1、除非另有特别声明，挚享科技提供本服务时所依托的硬件、软件的著作权、专利权及其他知识产权均归挚享科技所有，挚享科技提供服务的过程中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归挚享科技所有。</p>
                        <p>2、除非法律允许或本公司的书面许可，用户使用本软件过程中不得从事下列行为：</p>
                        <p>（1）删除本软件及其副本上关于著作权的信息；</p>
                        <p>（2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</p>
                        <p>（3）对挚享科技拥有知识产权的内容进行使用、出租、出借、复制、修改、汇编、发表、出版、建立镜像站点等；</p>
                        <p>（4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经本公司授权的第三方工具/服务接入本软件和相关系统；</p>
                        <p>（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
                        <p>（6）通过非挚享科技开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件及服务，或制作、发布、传播上述工具；</p>
                        <p>（7）自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰。</p>
                        <p>（8）其他违反法律法规或公序良俗行为。</p>
                        <p>六、法律责任</p>
                        <p>1、用户理解并同意，挚享科技有权依合理判断对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</p>
                        <p>2、用户理解并同意，因用户违反本条款约定，导致或产生的任何第三方向挚享科技主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿挚享科技与合作公司、关联公司，并使之免受损害。</p>
                        <p>七、不可抗力及其他免责事由</p>
                        <p>1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，挚享科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，挚享科技及合作单位在法律允许的范围内免责。</p>
                        <p>2、用户理解并确认，本服务存在因计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，挚享科技不承担任何责任。</p>
                        <p>3、用户理解并确认，挚享科技需要定期或不定期地对本软件或本服务进行检修或者更新维护，如因此类情况而造成服务在合理时间内的中断，挚享科技无需为此承担任何责任。</p>
                        <p>4、用户理解并确认，用户应严格按照本公司发布的规范进行相关操作，如由于未严格按照操作规范导致用户未能享受相关服务或造成损失的，由此产生的责任用户应自行承担。</p>
                        <p>八、法律管辖适用及其他</p>
                        <p>1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向上海人民法院提起诉讼。</p>
                        <p>2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
                        <p>3、本协议未明示授权的其他权利仍由挚享科技保留，用户在行使这些权利时须另外取得挚享科技的书面许可。挚享科技如果未行使前述任何权利，并不构成对该权利的放弃。</p>
                        <p>4、挚享科技可以随时通过“怪兽充电”软件系统发布新的用户协议以更新本协议，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本程序查阅最新版协议条款。在挚享科技修改协议条款后，如果用户不接受修改后的条款，请立即停止使用挚享科技提供的服务，用户继续使用挚享科技提供的服务将被视为接受修改后的协议。
                            </p>

                    </div>
                </Modal>
            </div>
        )
    }
}
export default ProtocolLoginPage