import React, { Component } from 'react'
import circleLoadingModule from './circleLoading.module.less'

class CircleLoading extends Component {
    componentDidMount() {
        let innerWraper = document.querySelector(`.${circleLoadingModule.innerWraper}`)
        let innerText = document.getElementById('innerText')
        let angle = 0;
        // loadTime 十次
        this.timer = setInterval(function () {
            angle += 36;
            if (angle > 360) {
                clearInterval(this.timer);
            } else {
                innerWraper.setAttribute('style',`width: ${parseInt(angle * 100 / 360) + '%'}`)
                setInnerText(angle)
            }
        }, this.props.loadTime * 100);

        function setInnerText(angle) {
            innerText.innerHTML = `正在签约中（${parseInt(angle * 100 / 360) + '%'}）…`;
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        return (
            <div>
                <div className={circleLoadingModule.progressWraper}>
                    <div className={circleLoadingModule.innerWraper}></div>
                </div>
                <div id="innerText" className={circleLoadingModule.innerText}>正在签约中（0%）…</div>
            </div>
        )
    }
}

export default CircleLoading