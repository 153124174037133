import React, { Component } from 'react'
import { List, InputItem, Button, Toast, WhiteSpace, Checkbox } from 'antd-mobile';
import loginModule from './login.module.less'
import { connect } from 'react-redux'
import { getCodeSrc, getMsgCode } from '@/requests'
import { login } from '@/actions/user'
import { Redirect, withRouter } from 'react-router-dom'
import pageRander from '@/utils/pageRander'
import ProtocolLoginPage from '@/views/Protocols/protocolLoginPage'

const mapStateToProps = state => ({
  isLogin: state.user.isLogin,
  isLoading: state.user.isLoading,
})

const COUNTNUM = 60
@pageRander
@withRouter
@connect(mapStateToProps, { login })
class Login extends Component {
  state = {
    phoneIsError: false,
    phoneNumber: '',
    smsCode: '',
    loginType: 5, // 验证码登录 为5
    codeSrc: '',
    token: '',
    vcode: '',
    countNumber: COUNTNUM,
    showCount: false,
    agreeLoginProtocol: false,
    isShowTips: false
  }

  componentDidMount() {
    this.getCodeSrc()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  getCodeSrc = () => {
    getCodeSrc()
      .then(res => {
        if (res.success) {
          let model = res.model
          localStorage.setItem('token', model.token) // 图形验证码token
          this.setState(() => {
            return {
              codeSrc: `data:image/png;base64,${model.kaptcha}`,
              token: model.token
            }
          })
        }
      })
  }
  onErrorClick = () => {
    if (this.state.phoneIsError) {
      Toast.info('请输入正确的手机号码！');
    }
  }
  onChange = (phoneNumber) => {
    // phoneNumber = phoneNumber.replace(/\s/g, '')
    if (phoneNumber.replace(/\s/g, '').length < 11 ||
      !phoneNumber.startsWith(1)) {
      this.setState({
        phoneIsError: true,
      });
    } else {
      this.setState({
        phoneIsError: false,
      });
    }
    this.setState({
      phoneNumber,
    });
  }
  extraHandle = e => {
    const { phoneIsError, phoneNumber, vcode, token } = this.state
    if (phoneIsError || vcode.length < 4) return

    getMsgCode({
      phoneNumber: phoneNumber.replace(/\s/g, ''),
      vcode,
      token
    })
      .then(res => {
        Toast.info(res.success ? '验证码发送成功' : res.errorMessage)
        if (!res.success) {
          this.getCodeSrc()
          this.setState({
            vcode: ''
          })
          this.resetCounter()
        }
      })
      .catch(error => {
        this.resetCounter()
        console.error(error);
      });
  }
  resetCounter = () => {
    clearInterval(this.timer)
    this.setState({
      showCount: false,
      countNumber: COUNTNUM
    })
  }
  loginHandle = () => {
    const { login: loginHandle, history } = this.props
    let { phoneNumber, smsCode, loginType: loginModel, agreeLoginProtocol } = this.state

    if (!agreeLoginProtocol) {
      this.setState({
        isShowTips: true
      })
      return
    }
    phoneNumber = phoneNumber.replace(/\s/g, '')
    loginHandle({
      phoneNumber,
      smsCode,
      loginModel,
      history
    })
  }
  codeChange = (smsCode) => {
    this.setState({
      smsCode
    })
  }
  imageHandle = vcode => {
    this.setState({
      vcode
    })
  }
  countDownHandle = () => {
    const { phoneIsError, vcode, showCount } = this.state
    if (phoneIsError || vcode.length < 4 || showCount) return

    this.extraHandle()
    this.setState(preState => ({
      countNumber: preState.countNumber - 1,
      showCount: true
    }))
    this.timer = setInterval(() => {
      if (this.state.countNumber <= 1) {
        clearInterval(this.timer)
        this.setState((preState) => ({
          showCount: false,
          countNumber: COUNTNUM
        }))
      }
      this.setState(preState => ({
        countNumber: preState.countNumber - 1
      }))
    }, 1000)
  }

  render() {
    const { phoneIsError, phoneNumber, smsCode, codeSrc, vcode, showCount, countNumber, agreeLoginProtocol, isShowTips } = this.state
    const { isLogin, isLoading } = this.props
    return (
      isLogin ?
        <Redirect to="/admin" />
        :
        <div className={loginModule.loginWrapper}>
          <div className={loginModule.loginBox}>
            <div className={loginModule.logo}>
              < img className="full-width"
                src={
                  require(`@/assets/images/login_top.png`)
                }
                alt="怪兽充电渠道合作签约平台"/>
              {/* <img alt="logo" className="test" src={logo} /> */}
            </div>
            <div className="login-item-wrapper">
              <List>
                <InputItem
                  type="phone"
                  placeholder="请输入手机号"
                  clear
                  disabled={isLoading}
                  error={phoneIsError}
                  onErrorClick={this.onErrorClick}
                  onChange={this.onChange}
                  value={phoneNumber}></InputItem>
              </List>
              <WhiteSpace />
              <List className="code">
                <InputItem
                  placeholder="请输入图形校验码"
                  disabled={isLoading}
                  type="text"
                  maxLength={4}
                  value={vcode}
                  onChange={this.imageHandle}
                  extra={<img src={codeSrc} alt="校验码" onClick={this.getCodeSrc} />} />
              </List>
              <WhiteSpace />
              <List>
                <InputItem
                  placeholder="请输入验证码"
                  type="number"
                  maxLength={6}
                  value={smsCode}
                  disabled={isLoading}
                  onChange={this.codeChange}
                  extra={showCount ? <span>{countNumber < 10 ? `0${countNumber}` : countNumber}s后重发</span> : <span onClick={this.countDownHandle} className={(phoneIsError || vcode.length < 4) ? '' : 'primary'}>获取验证码</span>}></InputItem>
              </List>
              <WhiteSpace />
              <div className={["pall10 box-flexalign-items-center", loginModule.protoTisContent].join(' ')}>
                {isShowTips && <span className={loginModule.protoTis}>请先勾选“用户协议、隐私条款”</span>}
                <Checkbox
                  checked={agreeLoginProtocol}
                  onChange={(event) => {
                    this.setState({ agreeLoginProtocol: event.target.checked })
                    if (isShowTips) {
                      this.setState({ isShowTips: false })
                    }
                  }}
                >
                  <span className="ml5 gray text-vertical">
                    <span>登录即表示同意</span>
                  </span>
                </Checkbox>
                <span className="primary text-vertical" onClick={() => this.protocolLogin.showModal()} >用户协议、隐私条款</span>
              </div>
              <List className="mt20">
                <Button type="primary" loading={isLoading} onClick={() => this.loginHandle()} disabled={!(!phoneIsError && smsCode.length === 6 && vcode.length === 4)}>登录</Button>
              </List>
            </div>
            <div className={loginModule.logoBottom}>
              < img className="full-width"
                src={
                  require(`@/assets/images/login_bottom.png`)
                }
                alt="怪兽充电渠道合作签约平台" />
              {/* <img alt="logo" className="test" src={logo} /> */}
            </div>
          </div>
          < ProtocolLoginPage onRef={ref => this.protocolLogin = ref} />
        </div>
    )
  }
}
export default Login
