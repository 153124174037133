import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { PullToRefresh, ListView, Icon, Toast, Button } from 'antd-mobile'
import signCenterModule from '@/views/SignCenter/signCenter.module.less'
import { querySignList, queryBaseInfo } from '@/requests'
import Activity from '@/components/activity/index'

const LISTCOUNT = 10
@withRouter
class SignListLoad extends Component {
    constructor(props) {
        super(props)
        //这个dataSource有cloneWithRows方法
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        })

        this.pageNo = 1 //定义分页信息
        this.state = {
            dataSource,
            allData: [],
            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight - 240, // 头部+底部高度
            hasMore: true,
            allSelect:false,
            checkSemi:false,
            checkIdList: [],
            loginAccountType: localStorage.getItem('loginAccountType')
        }
    }

    componentDidUpdate() {
    }
    async queryBaseInfo() {
        // 查询自然人
        let {accountType, queryType} = this.props
        if(!(accountType === 2 && queryType * 1 === 1)) return {success: false}
        let {success, model: {registerUrl = '', waitSignTip = '', registerIsShow = 0, registerEnable = 0} = {}} = await queryBaseInfo()
        if(success) {
            return {
                success,
                registerUrl,
                registerEnable,
                waitSignTip,
                registerIsShow
            }
        } else {
            return {
                success
            }
        }
        
    }
    async componentDidMount() {
        const hei = this.state.height
        this.rData = await this.genData(true)

        let {changeTab} = this.props
        let {success, registerEnable, registerIsShow, registerUrl, waitSignTip = ''} = await this.queryBaseInfo()
        if(success) {
            if(registerIsShow === 1) {
                changeTab(1)
                this.rData.push({
                    isRegItem: true,
                    registerUrl,
                    registerEnable,
                    waitSignTip
                })
            }
        }
        if(this.props.queryType * 1 === 1){
            // 待签约tab

            // 初始化同一赛道
            const node = this.rData.find(item => item.mustSelect === true) 
            if(node){
                // 有必须签约的
                if(node.onlySelect){
                    // 只能唯一签约
                    this.rData.forEach((val)=>{
                        if(val.documentId !== node.documentId){
                            val.disabled = true
                        }
                    })
                    this.setState({
                        allSelect:true
                    })
                }else{
                    // 不是只能唯一签约
                    this.rData.forEach((val)=>{
                        if(val.signChannel !== node.signChannel){
                            val.disabled = true
                        }
                    })
                    if(this.rData.length === 1){
                        // 只有一条数据 设置全选
                        this.setState({
                            allSelect:true
                        })
                    }else{
                        // 设置半选
                        this.setState({
                            allSelect:false,
                            checkSemi:true
                        })
                    }
                }
            }

            let checkIdList = [];
            this.rData.forEach((val)=>{
                if(val.isSelect){
                    checkIdList.push(val)
                }
            })
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(this.rData))),
                allData: this.rData,
                height: hei,
                refreshing: false,
                isLoading: false,
                checkIdList: checkIdList
            })
        }else{
            // 已签约tab
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(this.rData))),
                allData: this.rData,
                height: document.documentElement.clientHeight,
                refreshing: false,
                isLoading: false,
            })
        }
        
    }

    componentWillUnMount = () => {
        this._isMounted = false;
    }

    async genData(init) {  //请求数据的方法
        this._isMounted = true;

        if (this._isMounted) {
            if (init) {
                this.pageNo = 1
                this.rData = []
            } else {
                //每次下拉的时候pageNo++      
                this.pageNo++
            }
        }

        let params = {
            pageIndex: this.pageNo,
            pageSize: LISTCOUNT,
            model: {
                queryType: this.props.queryType * 1
            }
        }
        if(this.props.queryType * 1 === 1){
            // 待签约 tab 下 一次拿完，不做分页处理
            params.pageSize = 50
        }
        const loginAccountType = this.state.loginAccountType
        let signRequest = await querySignList(params, loginAccountType == 3 ? 'partner' : 'agent')
        if (signRequest.success) {
            let modelList = signRequest.model || [];
            modelList.forEach(element => {
                element.isSelect = element.mustSelect || false
                element.disabled = false
            });
            return modelList
        } else {
            Toast.fail(signRequest.errorMessage)
            return []
        }
    }

    onRefresh = async (event) => {
        console.log('下拉刷新')
        this.rData = await this.genData(true)

        const node = this.rData.find(item => item.mustSelect === true)
        if(node){
            this.rData.forEach((val)=>{
                if(val.signChannel !== node.signChannel){
                    val.disabled = true
                }
            })
        }

        let {success, registerUrl, registerEnable, registerIsShow, waitSignTip} = await this.queryBaseInfo()
        if(success) {
            if(registerIsShow === 1) {
                this.rData.push({
                    isRegItem: true,
                    registerUrl,
                    registerEnable,
                    waitSignTip
                })
            }
        }
        this.setState({
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(this.rData))),
            allData: this.rData,
        },()=>{
            this.setAllbtn()
        })
    }

    onEndReached = async (event) => {
        console.log('到底部')
        if(this.props.queryType * 1 === 1){
            return
        }
        if (this.state.isLoading && !this.state.hasMore) {
            return
        }
        this.setState({ isLoading: true })
        this.rData = [...this.rData, ...(await this.genData())]  //每次下拉之后将新数据装填过来


        const node = this.rData.find(item => item.mustSelect === true)
        if(node){
            // 有必须签约的
            if(node.onlySelect){
                // 只能唯一签约
                this.rData.forEach((val)=>{
                    if(val.documentId !== node.documentId){
                        val.disabled = true
                    }
                })
                this.setState({
                    allSelect:true
                })
            }else{
                // 不是只能唯一签约
                this.rData.forEach((val)=>{
                    if(val.signChannel !== node.signChannel){
                        val.disabled = true
                    }
                })
                if(this.rData.length === 1){
                    // 只有一条数据 设置全选
                    this.setState({
                        allSelect:true
                    })
                }else{
                    // 设置半选
                    this.setState({
                        allSelect:false,
                        checkSemi:true
                    })
                }
            }
        }
        
        this.setState({
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(this.rData))),
            allData: this.rData,
            isLoading: false,
        },()=>{
            this.setAllbtn()
        })

    }

    // 签约文件
    goSignDetail = (documentId, {isRegItem, registerUrl, registerEnable, waitSignTip, markGrey ,disabled}) => {
        if(markGrey === 1){
            // 置灰不可以点击
            return
        }
        if(isRegItem) {
            if(registerEnable === 1) {
                window.location.href = registerUrl
            }else {
                Toast.show(waitSignTip)
            }
        }else {
            if(disabled){
                Toast.info('该文件暂不可和其他文件一起签约，请选择其他文件进行签约')
                return
            }
            let documentIds = [documentId]
            this.props.history.push(`/signInfo?documentIds=${documentIds}`)
        }
        
    }

    selectWrapClick = (e,signItem) =>{
        e.stopPropagation();
        if(signItem.disabled){
            Toast.info('该文件暂不可和其他文件一起签约，请选择其他文件进行签约')
            return
        }
        if(!signItem.isSelect && this.state.checkIdList.length === 10){
            Toast.info('ECB仅支持一次签约10份协议！')
            return
        }
        if(signItem.mustSelect){
            return
        }
        const list = this.state.dataSource._dataBlob.s1;
        list.forEach((val)=>{
            if(signItem.documentId === val.documentId){
                val.isSelect = !val.isSelect
            } 

            if(signItem.mustSelect){
                val.isSelect = true
            }  
        })

        const node = list.find(item => item.isSelect === true) // 找到那个是选择了的
        if(node){
            // 如果有
            list.forEach((val)=>{
                if(val.signChannel !== node.signChannel){
                    val.disabled = true
                }
            })
        } else{
            // 如果没有
            list.forEach((val)=>{
                val.disabled = false
            })
        }
        // 检查 select 为 true 的 push 到 【】里
        let checkIdList = [];
        list.forEach((val)=>{
            if(val.isSelect){
                checkIdList.push(val)
            }
        })

        this.setState({
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(list))),
            checkIdList: checkIdList
        },()=>{
            this.setAllbtn()
        })
    }

    selectAll = ()=> {
        const allSelect = this.state.allSelect;
        const list = this.state.dataSource._dataBlob.s1;
        list.forEach((val)=>{
            if(!val.disabled){
                val.isSelect = !allSelect
            }
            if(val.mustSelect){
                val.isSelect = true
            }
        })

        
        const node = list.find(item => item.isSelect === true) // 找到那个是选择了的
        if(!node){
            list.forEach((val)=>{
                val.disabled = false
            })
        }
        
        this.setState({
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(list))),
            allSelect : !allSelect,
        },()=>{
            const list = this.state.dataSource._dataBlob.s1;
            let bol = false;
            let onlySelectIs = false;
            list.forEach((val)=>{
                if(val.isSelect){
                    bol = true
                }
                if(val.isSelect && val.onlySelect){
                    // 唯一签约是选
                    onlySelectIs = true
                }
            })
            if(onlySelectIs){
                this.setState({
                    allSelect:true
                }) 
                const list = this.state.dataSource._dataBlob.s1;
                const node = list.find(item => item.mustSelect === true) // 找到是唯一选择的
                let arr = [];
                    arr.push(node)
                    this.setState({
                        checkIdList:arr
                    })
            }else{
                this.setState({
                    checkSemi:bol
                },()=>{
                    if(!this.state.checkSemi && !this.state.allSelect){
                        // 没有半选择, 也咩有全选
                        this.setState({
                            checkIdList:[]
                        })
                    }else if(this.state.checkSemi){
                        const list = this.state.dataSource._dataBlob.s1;
                        const node = list.find(item => item.mustSelect === true) // 找到那个是必须选择的
                        let arr = [];
                            arr.push(node)
                        this.setState({
                            checkIdList:arr
                        })
                    }
                })
            }
        })
        
    }

    setAllbtn = ()=> {
        let list = this.state.dataSource._dataBlob.s1, arr = [];
        list.forEach((val)=>{
            if(!val.disabled){
                arr.push(val)
            }
        })
        let bol = false; 
        let checkSemi = false;
        let onlySelectIs = false;
        arr.forEach((val)=>{
            if(val.isSelect){
                bol = true; //是否有一个是勾选的 
            }
            if(!val.isSelect){
                checkSemi = true // 是否有一个是没有勾选的
            }
            if(val.isSelect && val.onlySelect){
                // 唯一签，是选定状态
                onlySelectIs = true
            }
        })

        if(bol && checkSemi){
            // 有一个是勾选的，也有一个是没有勾选
            this.setState({
                checkSemi:true, // 半选
                allSelect:false // 全选
            }) 
            if(onlySelectIs){
                // 唯一签选定状态
                this.setState({
                    checkSemi:false, // 半选
                    allSelect:true // 全选
                })  
            }
        }else if(bol && !checkSemi){
            // 全都是勾选的
            this.setState({
                checkSemi:false, // 半选
                allSelect:true // 全选
            }) 
        }else if(!bol){
            // 全都没有勾选
            this.setState({
                checkSemi:false, // 半选
                allSelect:false // 全选
            }) 
        }
    }

    batchSelect = ()=>{
        let list = this.state.dataSource._dataBlob.s1 || [], arr = [];
        list.forEach((val)=>{
            if(val.isSelect){
                arr.push(val)
            }
        })
        let documentIds = arr.map(item=>item.documentId) || []
        if(documentIds.length){
            this.props.history.push(`/signInfo?documentIds=${documentIds}`)
        }
    }

    render() {
        let { queryType } = this.props
        let { allSelect, checkSemi, checkIdList, allData } = this.state
        //这里就是个渲染数据，rowData就是每次过来的那一批数据，已经自动给你遍历好了，rouID可以作为key值使用，直接渲染数据即可
        const row = (rowData, rowID) => {
            let signItem = rowData
            return (
                <li className="plr14 mt10" key={rowID} onClick={() => this.goSignDetail(signItem.documentId, signItem)} >
                    <div className={[signCenterModule.signInfoItem, signItem.disabled ? signCenterModule.signInfoItemDis : '', 'box-flex align-items-center plr20tb10 font-sml', signItem.markGrey === 1 ? 'disabled':''].join(' ')}>
                        {signItem.isRegItem ? 
                        <div className="flex-1 box-flex flex-column justify-between">
                            <div className="black mb9 font-mid font-bold-sml">注册提现账户</div>
                            <div className="warning mb10 font-mini text-line-sml">
                            为了您的分润可以安全合规提现，请点击进入完成注册流程
                            </div>
                        </div> :
                        <div className="flex-1 box-flex flex-row justify-between">
                            {
                                queryType === '1'&&<div className={signCenterModule.selectWrap} onClick={(e) => this.selectWrapClick(e,signItem)}>
                                {/* 4种样式，
                                1，未选中，可以选择 (有选中的文件，同一渠道可以选择) select = false class ==> signCenterModule.selectNo
                                2，选中，被选择 isSelect = true
                                3，默认选中 + disabled， (默认选中文件) select + mustSelect = true
                                4，未选中不可选，(有选中的文件，不同渠道可以选择)
                                 */}
                                    <div className={[ 
                                        signCenterModule.select, 
                                        signItem.isSelect ? signCenterModule.selectTrue : signCenterModule.selectNo,
                                        signItem.mustSelect ? signCenterModule.selectTrueDis : ''
                                        ].join(" ")}>
                                        {(signItem.isSelect || signItem.mustSelect) && <div className={signCenterModule.check}></div>}
                                    </div>
                                </div>
                            }
                            {
                                queryType === '2'&& <div className={signCenterModule.noSelectWrap}></div>
                            }
                            <div style={{flex:14}}>
                                {signItem.signProcessDesc &&
                                    <div className="danger mb9 font-mini text-line-sml">
                                        {signItem.signProcessDesc}
                                    </div>
                                }
                                <div className="block-243B3C font-sml font-bold-mid" style={{ marginLeft: '-6px' }}>{signItem.businessTypeDesc}</div>
                                {signItem.status === 2 && 
                                    <div className="mt5">
                                        <span className="text-break font-mini">签约时间：{signItem.signTime}</span>
                                    </div>
                                }
                                <div className="mt5">
                                    <span className="text-break font-mini">文件编码：{signItem.documentNo}</span>
                                </div>
                                {signItem.expireDateTime &&
                                    <div className="mt5 warning">
                                        <span className={[signCenterModule.signInfoLabel, 'warning'].join(' ')}>签署到期时间:</span>
                                        <span className="text-break font-mini">{signItem.expireDateTime}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        }
                        <div>
                            <Icon type="right" color="#243B3C" />
                        </div>
                    </div>
                </li>
            )
        }
        return (
            <div>
                {this.props.isHasAnyData ? 
                    <div>
                        <ListView
                            key='1'
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderFooter={    //renderFooter就是下拉时候的loading效果
                                () => (
                                    <div style={{ padding: 30, textAlign: 'center' }}>
                                        {this.state.isLoading ? '加载中...' : queryType * 1 === 1 ? '暂无可签约文件' : '暂无已签约文件'}
                                    </div>
                                )
                            }
                            renderRow={row}   //渲染你上边写好的那个row
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                                // border: '1px solid #ff0000'
                            }}
                            pullToRefresh={<PullToRefresh
                                refreshing={this.state.refreshing}
                                onRefresh={this.onRefresh}
                            />}
                            onEndReached={this.onEndReached}
                            pageSize={LISTCOUNT}    //每次下拉之后显示的数据条数
                        />
                        {
                            queryType * 1 === 1 && allData.length ? <div className={signCenterModule.waitSignBottomBtn}>
                                { checkIdList && checkIdList.length ? <div onClick={()=>this.selectAll()} className={signCenterModule.allSelectWrap}>
                                    <div className={signCenterModule.selectWrap}>
                                        <div className={[
                                                            signCenterModule.select, 
                                                            allSelect?signCenterModule.selectTrue:signCenterModule.selectNo, 
                                                            checkSemi&&!allSelect?signCenterModule.selectNo_checkSemi:''
                                                        ].join(" ")}>
                                            {allSelect&&<div className={signCenterModule.check}></div>} 
                                            {checkSemi&&!allSelect&&<div className={signCenterModule.checkSemi}></div>} 
                                        </div>
                                    </div>
                                    <div className='inline-block'>全选</div>
                                </div> : <div className={signCenterModule.allSelectWrap}></div>} 
                                <div className={signCenterModule.selectBtnWrap}>
                                    <Button disabled={!checkSemi && !allSelect} onClick={()=>this.batchSelect()} className={signCenterModule.selectBtn} type="primary">批量签约</Button>
                                </div>
                            </div> : <div></div>
                        }
                </div> : this.props.accountType === 1 ? <div><p className="pall15 mt40 text-center gray">暂无可签约文件</p><Activity></Activity></div>: <p className="pall15 mt40 text-center gray">暂无可签约文件</p> }
            </div>
        )
    }
}

export default SignListLoad
