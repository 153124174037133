import React, { Component } from "react";
import GsIcon from "@/components/gsicon/gsicon";
import { queryBdInfo } from "@/requests";
import { Toast } from "antd-mobile";

class BdPhone extends Component {
  state = {
    isLoading: false, // 点击loading
  };

  goTel = () => {
    let isLoading = this.state.isLoading;
    if (isLoading) return;
    this.setState({
      isLoading: true,
    });
    queryBdInfo()
      .then((res) => {
        let { success, errorMessage, model = {} } = res;
        if (success) {
          let phone = model.phone || "";
          if (phone) {
            window.location.href = `tel:${phone}`;
          } else {
            Toast.fail("暂无电话");
          }
        } else {
          Toast.fail(errorMessage);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    let { children, className, isHideIcon = false } = this.props;
    return (
      <span className={className} onClick={this.goTel}>
        {children}
        {children[0]}
        {!isHideIcon && <GsIcon name={"phone-white"} size={"15"} />}
        {children[1]}
      </span>
    );
  }
}

export default BdPhone;
