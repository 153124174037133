// 常规引入
import React, { Component } from 'react'
// import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { TabBar } from 'antd-mobile'
import homeModule from './home.module.less'

// 引入组件页面 
import SignCenter from '../SignCenter'
import PersonalCenter from '../PersonalCenter'

const mapStateToProps = state => ({
  isLogin: state.user.isLogin
})
@connect(mapStateToProps)
class Home extends Component {
  state = {
    selectedTab: ''
  }

  constructor(props) {
    super(props);
    //	重定向登录
    console.log(props, 'home')
    if (!props.isLogin) {
      props.history.replace('/login')
    } else {
      this.state = {
        //	重定向
        selectedTab: 'signTab',
      };
    }
  }

  // 组件页面跳转
  renderContent(component) {
    return (
      <div>{component}</div>
    );
  }

  render() {
    const { selectedTab } = this.state
    return (
      selectedTab && <div className={[homeModule.homeWrapper, 'homeResetWrapper'].join(' ')}>
        <TabBar
          unselectedTintColor="#989BA5"
          tintColor="#3fc9cd"
          barTintColor="white"
        >
          {/* 签约中心 */}
          <TabBar.Item
            title="签约中心"
            key="sign"
            icon={<div style={{
              width: '24px',
              height: '24px',
              background: `url(${require("@/assets/images/tab_sign.png")}) center center /  24px 24px no-repeat`
            }}
            />
            }
            // 选中后的展示图片
            selectedIcon={<div style={{
              width: '24px',
              height: '24px',
              background: `url(${require("@/assets/images/tab_sign_pre.png")}) center center /  24px 24px no-repeat`
            }}
            />
            }
            // 是否选中
            selected={selectedTab === 'signTab'}
            // bar 点击触发，需要自己改变组件 state & selecte={true}
            onPress={() => {
              this.setState({
                selectedTab: 'signTab',
              });
            }}
          >
            {selectedTab === 'signTab' && this.renderContent(<SignCenter />)}
          </TabBar.Item>
          {/* 个人中心 */}
          <TabBar.Item
            icon={
              <div style={{
                width: '24px',
                height: '24px',
                background: `url(${require("@/assets/images/tab_my.png")}) center center /  24px 24px no-repeat`
              }}
              />
            }
            selectedIcon={
              <div style={{
                width: '24px',
                height: '24px',
                background: `url(${require("@/assets/images/tab_my_pre.png")}) center center /  24px 24px no-repeat`
              }}
              />
            }
            title="我的"
            key="presonal"
            selected={selectedTab === 'presonalTab'}
            onPress={() => {
              this.setState({
                selectedTab: 'presonalTab',
              });
            }}
          >
            {selectedTab === 'presonalTab' && this.renderContent(<PersonalCenter />)}
          </TabBar.Item>
        </TabBar>
      </div>
    );
  }
}

export default Home
