import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-photo-view/dist/index.css'
import { Button, Toast } from 'antd-mobile'
import { accountResult, queryAccountInfo, batchSignResult } from '@/requests'
import Activity from '@/components/activity/index'
import signResult from './signResult.module.less'
import Card from '@/components/card/card';
import { getQueryVariable } from '../../../utils/tool'

const resultIconList = [ 'sign_load.png', 'sign_success.png', 'sign_fail.png' ]
const colorList = ['warning','primary','danger']
const resultTitle = [ '签约中', '签约成功', '请联系渠道经理，重新确认流程或发起签约' ]

@withRouter
class SignResult extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    iniState() {
        console.log('iniState@@')
        this.setState({
            isGetSign: -1,
            showLoading: false,
            accountInfo:{},
            accountResult:{}, // 开户结果
            taskShow:false,
            source:'',
            batchBizNo:'', // 批量签约编号
            successCount:0, // 成功数量
            failCount:0, // 失败数量
            waitCount:0, // 待签约数量
            bindCard:false, // 是否走绑卡流程
            operateType:1,   // 操作类型 1. 同意 2. 拒绝
            requestResult:1,  //处理结果 0. 处理中 1. 处理成功 2. 处理失败
            firstSign:true, // 是否是首次签约
            type:'',
            requestFailInfo:'',
            loginAccountType: ''
        })
    }

    // 获取路由参数
    componentDidMount() {
        this.iniState()
        let routerProps = this.props.location.search || '';
        let batchBizNo = getQueryVariable('batchBizNo', routerProps); // 批量签署编号
        const source = getQueryVariable('source', routerProps);
        const type = getQueryVariable('type',routerProps)
        this.setState({ source:source,type:type })
        this.setState({ batchBizNo: batchBizNo }, () => {
            this.renderPage()
        });
        this.setState({
            loginAccountType: localStorage.getItem('loginAccountType')
        },()=>{
            const loginAccountType =  this.state.loginAccountType
            // 获取当前登录角色信息
            queryAccountInfo(loginAccountType == 3 ? 'partner' : 'agent').then(res => {
                    if (res.success) {
                        this.setState({
                            accountInfo:res.model || {}
                        })
                    } else {
                        Toast.fail(res.errorMessage);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            })
        
    }

    //组件更新时被调用 
    componentWillReceiveProps(newProps) {

    }

    componentWillUnmount() {
    }

    renderPage = () => {
        this.getBatchSignResult()
    }



    getBatchSignResult = () => {
        let batchBizNo = this.state.batchBizNo
        const loginAccountType = this.state.loginAccountType
        if (!batchBizNo) {
            return
        }
        this.setState({
            showLoading:true
        })
        batchSignResult({
            batchBizNo:batchBizNo
        }).then(res=>{
            this.setState({
                showLoading:false
            })
            if(res.success){
                this.setState({
                    bindCard: res.model.bindCard,
                    successCount: res.model.successCount,
                    failCount: res.model.failCount,
                    operateType: res.model.operateType,
                    waitCount: res.model.waitCount,
                    requestResult: res.model.requestResult,
                    firstSign: res.model.firstSign,
                    requestFailInfo: res.model.requestFailInfo
                })
                if (res.model.bindCard) {
                    // 需要绑卡
                    accountResult(loginAccountType===3 ? 'partner' : 'agent').then(res=>{
                        if(res.success){
                            this.setState({
                                accountResult: res.model || {}
                            })
                        }
                    })
                }
            }else{
                Toast.fail(res.errorMessage);
            } 
        })
    }




    // 签约首页
    handleGoAdmin() {
        this.props.history.replace(`/admin`)
    }

    // 去绑卡
    goBindCard() {
        this.props.history.replace(`/bindingCard`)
    }

    //下次绑卡
    nextBindCard() {
        this.setState({ taskShow:!this.state.taskShow })
    }

    goSign = ()=> {
        this.props.history.replace(`/admin`)
    }

    render() {
        let {
            isGetSign,
            showLoading,
            requestResult,
            accountInfo,
            taskShow,
            accountResult,
            source,
            bindCard,
            firstSign,
            operateType,
            successCount,
            failCount,
            waitCount,
            type,
            requestFailInfo
        } = this.state

        return (
            <div>
                {showLoading ?
                    <div className="full-width text-center mt40">
                    </div>
                    :
                    (isGetSign ?
                        <div className={`${signResult.signResultWrapper}`}>
                            {/* 不是绑卡 */}
                            {source !== 'bindingCarding' && 
                                <div className="pall20 text-center font-midxl font-bold-sml">
                                    {/* 同意 */}            
                                    {operateType * 1 === 1 && 
                                        <div className="mt20">
                                            <img className="full-width" src={require(`@/assets/images/${resultIconList[requestResult]}`)} alt="签约结果"/> 
                                            <p className={`${colorList[requestResult]} ${signResult.fs40}`}>{resultTitle[requestResult]} </p>
                                            { requestFailInfo && <p className={`${colorList[requestResult]} mt10 font-mini`}>{requestFailInfo}</p>}
                                            { firstSign && <p className='primary mt10 font-mini'>欢迎您成为怪兽充电的加盟代理商</p> }
                                            { (requestResult * 1 === 1 || requestResult * 1 === 2) && <p className='primary mt10 font-mini'>
                                                                            { successCount > 0 && <span>成功签约{successCount}份协议</span> } 
                                                                            { successCount > 0 && failCount > 0 && <span>，</span> }  
                                                                            { failCount > 0 && <span>签约失败{failCount}份协议</span> }
                                                                            { failCount > 0 && waitCount > 0 && <span>，</span> }
                                                                            { successCount > 0 && failCount === 0 && waitCount > 0 &&<span>，</span> }
                                                                            { waitCount > 0 &&<span>还有{waitCount}份协议待签约</span> }
                                                                        </p>}
                                        </div>}
                                    {/* 拒绝 */}
                                    {operateType * 1 === 2 && 
                                        <div  className="mt20">
                                            <img className="full-width" src={require(`@/assets/images/sign_fail.png`)} alt="签约结果"/> 
                                            <p className='danger'>签约失败</p>
                                            <p className='primary mt10 font-mini'>请联系渠道经理，重新确认流程或发起签约。</p>
                                            {requestFailInfo && <p className='primary mt10 font-mini'>{requestFailInfo}</p>}
                                        </div>}
                                </div>}
                                {/* 邦卡 */}
                                {source === 'bindingCarding' && !type && 
                                        <div className="pall20 text-center font-midxl font-bold-sml">
                                            <div className="mt20">
                                                <img className="full-width" src={require(`@/assets/images/${resultIconList[requestResult]}`)} alt="签约结果"/> 
                                                <p className={`${colorList[requestResult]} ${signResult.fs40}`}>银行卡绑定成功</p>
                                            </div>
                                        </div>
                                }
                                {source === 'bindingCarding' && type === 'fail'&& 
                                        <div className="pall20 text-center font-midxl font-bold-sml">
                                            <div className="mt20">
                                                <img className="full-width" src={require(`@/assets/images/sign_fail.png`)} alt="签约结果"/> 
                                                <p className='danger'>银行卡绑卡认证失败</p>
                                            </div>
                                        </div>
                                }
                            {requestResult * 1 === 1 && source !== 'bindingCarding' && <div className="mt20 text-center text-line-sml warning font-sml">
                                                            <span className="primary font-sml" onClick={() => this.goSign()}>查看签约文件</span>
                                                        </div>}
                            <div className={`${signResult.bottomButton}`}>
                                <div onClick={() => this.handleGoAdmin()}> {'返回首页'} </div>
                            </div>
                            {/* 有待绑卡信息 && 中信银行 */}
                            {bindCard && accountResult.status === 3 && accountResult.bankType === 1 &&
                                <div className={signResult.bingCardWrap}>
                                    <div className={signResult.title}>有一张银行卡待绑定，是否现在绑定？</div>
                                    <Card bankName={accountResult.bankName} bankNo={accountResult.bankNo}></Card>
                                    <div className={signResult.btn} onClick={() => this.goBindCard()}>去绑定</div>
                                    <div className={signResult.btn2} onClick={() => this.nextBindCard()}>下次绑定</div>
                                    { taskShow &&
                                        <div className={signResult.task}>
                                        <div className={signResult.wrap}>
                                            <div className={signResult.text1}>后续可以在【我的】-【绑定银行卡】-【待绑定】标签页中绑定银行卡。</div>
                                            <div className={signResult.btnWrap}>
                                                <div className='box-flex flex-1 align-items-center justify-center' onClick={() => this.nextBindCard()}>取消</div>
                                                <div onClick={() => this.handleGoAdmin()}  className={`box-flex flex-1 align-items-center justify-center font-weight600 ${signResult.borderleft}`}>返回首页</div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                            {/* 非自然人 */}
                            {accountInfo.accountType === 1 &&<Activity tipsText={'新加盟代理商优惠活动'}></Activity>}
                        </div>
                        :
                        <div>
                            <div className='centerWrapper'>
                                <div className='centerWrapperContent'>
                                    <p className="pall20 font-midl font-bold-mid primary"></p>
                                    <div className="text-center mt20">
                                        <Button onClick={() => this.handleGoAdmin()} type="primary" inline className="ml10">返回签约中心</Button>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
            </div>
        )
    }
}

export default SignResult