import React, { Component } from 'react'
import codeModules from './code.module.less'
import { InputItem } from 'antd-mobile'
import PropTypes from 'prop-types';
class Code extends Component {

    state = {
        inputFocus:false,
        codeValue:'',
        list:[]
    }

    static propTypes = {
        codeMaxLength:PropTypes.number.isRequired,
        inputValueChange:PropTypes.func.isRequired
    }

    componentDidMount(){
        const { codeMaxLength } = this.props
        this.setState({list:[...Array(codeMaxLength).keys()]})
    }


    setInputFocus = () =>{
        this.setState({ inputFocus: !this.state.inputFocus });
        this.inputItemRef.focus()
    }

    cancelInputFocus = () =>{
        this.setState({ inputFocus: false });
    }

    inputChange = (value) => {
        this.setState({
            codeValue:value
        })
        const { inputValueChange } = this.props
        inputValueChange(value)
    }

    render() {
        const { inputFocus, codeValue, list } = this.state
        const { codeMaxLength } = this.props
        
        return(
            <div className={codeModules.wraper}>
                <div className={codeModules.verifyForm}>
                    {list.length && 
                        list.map((val,index)=>{
                            return  index === 0 ? 
                                    <span key={index} style={{marginLeft:'0px'}} className={ ( inputFocus && codeValue.length === val)? codeModules.highLight : ''}
                                        onClick={() => { this.setInputFocus() }}>
                                        {codeValue.slice(val, val+1)}
                                    </span> : 
                                    <span key={index} className={(inputFocus && codeValue.length === val && codeValue.slice(val-1, val)) ? codeModules.highLight : ''} 
                                        onClick={() => { this.setInputFocus() }}>
                                        {codeValue.slice(val, val+1)}
                                    </span>
                        })
                    }
                </div>
                <div className={codeModules.hidedInput}>
                    <InputItem
                        disabledKeys={['.']}
                        ref={el => this.inputItemRef = el}
                        placeholder="请输入验证码"
                        type="money"
                        maxLength={codeMaxLength}
                        value={codeValue}
                        onChange={this.inputChange}
                        onBlur={this.cancelInputFocus}>
                    </InputItem>
                </div>
            </div>
        )
    }
}

export default Code
