import React from 'react';
import ReactDOM from 'react-dom';
import "lib-flexible"
import * as serviceWorker from '@/serviceWorker';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '@/store'
import {
  mainRouter, adminRouter
} from '@/routes'
import '@/index.css';
import '@/common.css';
import '@/reset.css';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        {/* 非登录路由 */}
        {
          mainRouter.map(route => {
            return <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={(routeProps) => {
                document.title = route.meta ? route.meta.title : ''
                return <route.component {...routeProps} />
              }} />
          })
        }
        {/* 登录路由 */}
        {
          adminRouter.map(route => {
            return <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={(routeProps) => {
                document.title = route.meta ? route.meta.title : ''
                return <route.component {...routeProps} />
              }} />
          })
        }
        <Redirect to="/admin" from="/" exact />
        <Redirect to="/404" exact />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
