import React, { Component } from 'react'
import { PhotoProvider, PhotoConsumer } from 'react-photo-view'
import { withRouter } from 'react-router-dom'
import { queryBatchUrl } from '@/requests'
import { getQueryVariable } from '../../utils/tool'

@withRouter
class Picture extends Component {
    state = {
        ossKey:'',
        imgUrl:[]
    }

    // 组件挂载后输出
    componentDidMount() {
        let routerProps = this.props.location.search || '';
        const ossKey = getQueryVariable('ossKey', routerProps);
        if(!ossKey){
            return
        }
        this.setState({ ossKey: ossKey }, () => {
            this.getImgUrl()
        });
    }

     //组件更新时被调用
    componentWillReceiveProps(newProps) {
        // const newMountId = newProps.match.params.id
        // if (newMountId !== this.state.id) {
        //     this.setState({ id: newMountId }, () => {
        //         this.getImgUrl()
        //     });
        // }
    }

    // 获取协议地址
    getImgUrl = () => {
        let ossKey = this.state.ossKey
        let keyList = [];
            keyList.push(ossKey);
        if(!keyList || keyList.length === 0) return
        queryBatchUrl({ fileKeys: keyList }).then(res=>{
            if (res.success && res.model) {
                let urlList = []
                if (res.model && res.model.length) {
                  urlList = res.model.map(item => {
                    return item.fileUrl
                  })
                }
                this.setState({
                    imgUrl: urlList
                })
            } else {
                this.setState({
                    imgUrl: []
                })
            }
        })
    }

    render() {
        const { imgUrl } = this.state;
        return (
            <div className="bottomFixedContent">
                {imgUrl.length ? <PhotoProvider photoClosable="true">
                    {imgUrl.map((item, index) => (
                        <PhotoConsumer key={index} src={item}>
                            <img className="full-width" src={item} alt="签约文件" />
                        </PhotoConsumer>
                    ))}
                </PhotoProvider> :''
                }
            </div>
        )
    }
}
export default Picture