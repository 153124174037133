import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {  Badge ,Button, Toast, Flex } from 'antd-mobile';
import bankCardModel from './bankCard.module.less'
import { queryBankList } from '@/requests'
import { getQueryVariable } from '../../utils/tool'

const tabs = [
    { title: <Badge>待绑定</Badge> },
    { title: <Badge>已绑定</Badge> },
  ];

const RenderTabBar = (props) => {
    const { onTabClick, activeIndex } = props
    const changeTab = (tab,index) =>{ onTabClick(tab,index) }
    return(
        <Flex>
            {tabs.map((item, index) => {
                return <Flex.Item key={index}>
                    <div className={`text-center font-mid ${bankCardModel.tabBar}`} onClick={() => changeTab(item,index)}>
                        <span className={['commonTabName', index === activeIndex ? 'border-primary primary font-bold-mid' : 'border-trans'].join(' ')}>{item.title}</span></div>
                </Flex.Item>
            })}
        </Flex>
    )
}
class Card extends Component {
    render() {
        let list = this.props.list || ''
        return (
            list.length ? list.map((item, index) => {
                return  <div  key={index} className={[bankCardModel.bankCardLiUn].join(' ')}>
                            <p className='mb10 font-bold-mid font-midl'>{item.bankName}</p>
                            <p className='mb10'>储蓄卡</p>
                            <p className='mb10 font-midl letter-spacing10'>{item.accountNo}</p>
                        </div>
            }) : <div className={bankCardModel.noData}>暂未绑定银行卡</div>
        )
    }
}

class UnBindCard extends Component {
    render() {
        let list = this.props.list || ''
        const BindCardClick = (item,index) => { this.props.GetBindCardClick(item,index) }
        return (
            list.length ? list.map((item, index) => {
                return  <div onClick={() => {BindCardClick(item,index)}} key={index} className={[bankCardModel.bankCardLi, item.isChoose ? bankCardModel.isChoose :''].join(' ')}>
                            <p className='mb10 font-bold-mid font-midl'>{item.bankName}</p>
                            <p className='mb10'>储蓄卡</p>
                            <p className='mb10 font-midl letter-spacing10'>{item.accountNo}</p>
                            { item.isChoose &&<div className={`${bankCardModel.triangle}`}></div>}
                        </div>
            }) : <div className={bankCardModel.noData}>暂未待绑定银行卡</div>
        )
    }
}

@withRouter
class BankCard extends Component {
    constructor(props){
        super(props)
        this.state = {
            initialPage:0,
            bankList:[],
            unBankList:[],
            activeIndex:0,
            chooseBankInfo:{},
            domKey:Math.random()
        }
        this.itemClick = this.itemClick.bind(this)
    }

    componentWillMount(){
        let routerProps = this.props.location.search || '';
        const initialPage = getQueryVariable('initialPage', routerProps); // tab
        if(initialPage){
            this.setState({
                initialPage:Number(initialPage) || 0,
                activeIndex:Number(initialPage) || 0,
                domKey:Math.random()
            })
        }
    }

    componentDidMount() {
        this.getBankList()   
    }
    
    getBankList = () =>{
        queryBankList().then((result) => {
            if(!result.success){
                Toast.fail(result.errorMessage);
                return
            }
            let bankList = result.model.bankList || [];
            let unBankList = result.model.unBankList || [];
            bankList.forEach((item)=>{
                item.isChoose = false
            })
            unBankList.forEach((item)=>{
                item.isChoose = false
            })
            this.setState({
                bankList:bankList,
                unBankList:unBankList
            })
        }).catch((_err) => {
            
        });
    }

    tabClick = (tab, index) =>{
        this.setState({
            activeIndex:index
        })
    }

    itemClick = (item,index) =>{
        let unBankList = [...this.state.unBankList];
        unBankList.forEach((val,key)=>{
            val.isChoose = false
            if(val.bankId === item.bankId){
                val.isChoose = true
            }
        })
        this.setState({ 
            unBankList: unBankList,
            chooseBankInfo:item
        })
        
    }

    goBindBank = () =>  {
        const { bankId } = this.state.chooseBankInfo;
        this.props.history.push(`/bindingCard?cardId=${bankId}&comeFrom=bankList`)
    }

    render() {
        const { bankList, activeIndex, unBankList, chooseBankInfo, domKey } = this.state
        return (
            <div key={domKey}>
                <RenderTabBar activeIndex={activeIndex} onTabClick={(tab, index) => this.tabClick(tab, index)}></RenderTabBar>
                <div className={[bankCardModel.bankCardWrap,(activeIndex === 0 && unBankList.length) ? bankCardModel.paddingBottom :''].join(' ')}>
                    {activeIndex === 0 ?  <UnBindCard GetBindCardClick={(item,index) => {this.itemClick(item,index)}} list={unBankList}></UnBindCard> : <Card list={bankList}></Card>}
                    {(activeIndex === 0 && unBankList.length) ? <div className={bankCardModel.bottomBtn}>
                            <Button disabled={!Object.keys(chooseBankInfo).length} onClick={this.goBindBank} type="primary">绑定银行卡</Button>
                        </div> : ''
                    }
                </div>
                
            </div>
        )
    }
}

export default BankCard