import {
  Login,
  NotFound,
  SelectAccount,
  Home,
  AuthInfo,
  SignInfo,
  SignConfirm,
  SignResult,
  FaceAuth,
  BankCard,
  BindingCard,
  Picture,
  Loading
} from '../views'

export const mainRouter = [{
    path: '/login',
    component: Login,
    meta: {
      title: '签约登录'
    }
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
      title: '页面找不到了'
    }
  }, {
    path: '/selectAccount',
    component: SelectAccount,
    meta: {
      title: '选择渠道商'
    }
  }
]
export const adminRouter = [{
  path: '/admin',
  component: Home,
  meta: {
    title: '商户签约平台'
  }
}, {
  path: '/authInfo',
  component: AuthInfo,
  meta: {
    title: '实名认证'
  },
}, {
  path: '/signInfo',
  component: SignInfo,
  meta: {
    title: '签约文件'
  },
}, {
  path: '/signConfirm',
  component: SignConfirm,
  meta: {
    title: '签约短信验证'
  },
}, {
  path: '/signResult',
  component: SignResult,
  meta: {
    title: '签约结果'
  },
}, {
  path: '/faceAuth',
  component: FaceAuth,
  meta: {
    title: '人脸识别认证'
  },
},{
  path: '/bankCard',
  component: BankCard,
  meta: {
    title: '绑定银行卡'
  },
}, {
  path: '/bindingCard',
  component: BindingCard,
  meta: {
    title: '平安绑卡'
  },
}, {
  path: '/picture',
  component: Picture,
  meta: {
    title: '协议'
  },
}, {
  path: '/loading',
  component: Loading,
  meta: {
    title: '签约中'
  },
}]
