import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { WhiteSpace, Toast } from 'antd-mobile'
import signCenterModule from '../signCenter.module.less'
import { submitSign, sendSignSms } from '@/requests'
import { getQueryVariable } from '../../../utils/tool'
import Code from '@/components/code/code'

const COUNTNUM = 60
const pat = /(\d{3})\d*(\d{4})/

@withRouter
class SignConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            smsCode: '',
        }
    }

    iniState() {
        console.log('iniState@@')
        this.setState({
            documentIds: [],
            smsCode: '',
            countNumber: COUNTNUM,
            showCount: false,
            passLoginPhone: '',
            isLoading: false,
            businessType:'',
            errorCode:'',
            smsCodeInputFocus:false,
            timer: null,
            stimer: null,
            loginAccountType: localStorage.getItem('loginAccountType')
        })
    }

    // 获取路由参数
    componentDidMount() {
        let routerProps = this.props.location.search || '';
        let documentIds = getQueryVariable('documentIds', routerProps); 
            documentIds = documentIds.split(",").map(Number)
            clearInterval(this.timer)
            this.iniState()
            this.setState({
                documentIds: documentIds,
                passLoginPhone: localStorage.getItem('loginPhone').replace(pat, '$1****$2'),
            });
    }

    //组件更新时被调用 
    componentWillReceiveProps(newProps) {
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        clearTimeout(this.stimer)
    }

    // 同意签约
    signSubmit = () => {
        let params = {
            documentIds: this.state.documentIds,
            smsCode: this.state.smsCode,
            submitFlag: 1
        }
        this.setState({
            isLoading: true
        })
        const { loginAccountType } = this.state
        // 接口
        submitSign(params, loginAccountType === 3 ? 'partner' : 'agent')
            .then(res => {
                this.setState({
                    isLoading: false
                })
                if (res.success) {
                    this.props.history.push(`/loading?batchBizNo=${res.model}`)
                } else {
                    this.setState({
                        smsCode: '',
                        errorCode: res.errorCode
                    })
                    Toast.fail(res.errorMessage);
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                console.error(error);
            });
    }

    codeChange = (value) => {
        this.setState({smsCode:value},()=>{
            if (this.stimer !== null) clearTimeout(this.stimer);
            this.stimer = setTimeout(() => {
                if (value.length === 6 && !this.state.isLoading) {
                    this.signSubmit()
                }
            }, 500);
        })
    }
    
    extraHandle = (e) => {
        const { documentIds, loginAccountType } = this.state

        sendSignSms({documentIds:documentIds}, loginAccountType == 3 ? 'partner' : 'agent')
            .then(res => {
                Toast.info(res.success ? '验证码发送成功' : res.errorMessage)
                if (!res.success) {
                    this.resetCounter()
                }
            })
            .catch(error => {
                this.resetCounter()
                console.error(error);
            });
    }

    resetCounter = () => {
        clearInterval(this.timer)
        this.setState({
            showCount: false,
            countNumber: COUNTNUM
        })
    }

    countDownHandle = () => {
        if (this.state.showCount) return
        this.extraHandle()
        this.setState(preState => ({
            countNumber: preState.countNumber - 1,
            showCount: true
        }))
        this.timer = setInterval(() => {
            if (this.state.countNumber <= 1) {
                clearInterval(this.timer)
                this.setState((preState) => ({
                    showCount: false,
                    countNumber: COUNTNUM
                }))
            }
            this.setState(preState => ({
                countNumber: preState.countNumber - 1
            }))
        }, 1000)
    }



    render() {
        let { countNumber, showCount, passLoginPhone, errorCode } = this.state

        return (
            <div className={['font-sml', signCenterModule.signConfirmWrapper].join(" ")}>
                <div className={`${signCenterModule.topTip}`}>
                    为了保障你的签约安全，需要对签约手机号进行短信验证，带来的不便，请您谅解！
                </div>
                <WhiteSpace size="lg" />
                <div className={signCenterModule.bodyPadding}>
                    <div className="mb10">
                        <div className={signCenterModule.title}>联系电话</div>
                        <div className={`mt10 ${signCenterModule.value}`}>{passLoginPhone}</div>
                    </div>
                    <div>
                        <div className={signCenterModule.title}>请输入验证码</div>
                        <Code ref={el => this.codeChild = el} codeMaxLength={6} inputValueChange={(value) => this.codeChange(value)}></Code>
                        {errorCode === 'ECB110004' && <div className={`mt10 danger`}> 验证码输入错误，请输入正确的验证码。</div>}
                        <div className="mt10 pall10 text-center">
                            {showCount ? <span className={`${signCenterModule.color8C8C8C}`}>{countNumber < 10 ? `0${countNumber}` : countNumber}s后重发</span> : <span onClick={this.countDownHandle} className='primary'>获取验证码</span>}
                        </div>
                    </div>
                </div>

                
            </div>
        )
    }
}

export default SignConfirm