import React, { Component } from "react";
import activityModules from './index.module.less'
import { activityQuery } from '@/requests'
import { Toast, Button } from 'antd-mobile'
import BdPhone from '@/components/bdPhone/bdPhone'

class Activity extends Component {
    state = {
        activityNew:{},
        giveGift:{}
    }

    componentDidMount() {
        this.activityGet()
    }

    async activityGet (){
        const { model, success, errorMessage } = await activityQuery()
        if (success) {
            this.setState({
                activityNew: model.activityNew || {},
                giveGift: model.giveGift || {}
            })
        }else{
            Toast.fail(errorMessage);
        }
    }

    render() {
        const tipsText  = this.props.tipsText || '可先查看以下促销活动';
        const { activityNew, giveGift } = this.state
        return(
            <div className={`${activityModules.activityWrap}`}>
                <div className={`${activityModules.tip}`}>
                    <div className={`${activityModules.text}`}>{tipsText}</div>
                </div>
                {/* 新商分期活动 */}
                <div className={`${activityModules.activityIcon}`}>
                    <img className={`${activityModules.activityIconimg}`}src={require("@/assets/icons/activityIcon.png")} alt="" />
                    <span className={`${activityModules.dot}`}>{activityNew.no}</span>
                    <span className={`${activityModules.title}`}>{activityNew.activityName}</span>
                </div>
                <div className={`${activityModules.list}`}>
                    <div className={`${activityModules.listwrap}`}>
                        <div className={`block-243B3C font-bold-mid ${activityModules.borderno} ${activityModules.listli}`}>首付</div>
                        <div className={`block-243B3C font-bold-mid ${activityModules.borderno} ${activityModules.listli}`}>免息分期{activityNew.period}个月</div>
                    </div>
                    <div className={`${activityModules.listwrap}`}>
                        <div className={`align-self ${activityModules.listli}`}>{activityNew.downPaymentRate}</div>
                        <div className={`align-self ${activityModules.listli}`}>{activityNew.periodRate}</div>
                    </div>
                </div>
                <p className={`${activityModules.intro}`}>活动简介：{activityNew.remark}</p>
                {/* 满赠活动 */}
                <div className={`${activityModules.activityIcon}`}>
                    <img className={`${activityModules.activityIconimg}`}src={require("@/assets/icons/activityIcon.png")} alt="" />
                    <span className={`${activityModules.dot}`}>{giveGift.no}</span>
                    <span className={`${activityModules.title}`}>{giveGift.activityName}</span>
                </div>
                <div className={`${activityModules.list}`}>
                    <div className={`${activityModules.listwrap}`}>
                        <div className={`block-243B3C font-bold-mid ${activityModules.borderno} ${activityModules.listli}`}>购买数量</div>
                        <div className={`block-243B3C font-bold-mid ${activityModules.borderno} ${activityModules.listli}`}>赠送数量</div>
                    </div>
                    { giveGift.nums && giveGift.nums.length && giveGift.nums.map((item,index)=>{
                        return  <div key={index} className={`${activityModules.listwrap}`}>
                                    <div className={`align-self ${activityModules.listli}`}>买{item.buyNum}台柜机</div>
                                    <div className={`align-self ${activityModules.listli}`}>赠送{item.giftNum}台柜机</div>
                                </div>
                    })}
                </div>
                {giveGift.remarks && giveGift.remarks.length && <p className={`${activityModules.intro}`}>备注：</p>}
                {giveGift.remarks && giveGift.remarks.length && giveGift.remarks.map((item,index)=>{
                        return <p key={index} className={`${activityModules.intro}`}>{index+1}.{item}</p>
                })}
                {/* 电话 */}
                <div className={`${activityModules.phone}`}>
                    <BdPhone className="flex-1" isHideIcon="true">
                        <Button style={{ padding: '0 32px'}} type="primary">具体活动政策，可联系渠道经理  {'>'}</Button>
                    </BdPhone>
                </div>
            </div>
        )
    }
}

export default Activity